import { Box, Typography, Button } from "@mui/material"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import ClearAllIcon from '@mui/icons-material/ClearAll';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DownloadIcon from '@mui/icons-material/Download';
import TableChartIcon from '@mui/icons-material/TableChart';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { InputText } from "primereact/inputtext";
import { Form } from "./form";
import { Dialog } from 'primereact/dialog';
import SettingsIcon from '@mui/icons-material/Settings';
import { useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { updateModalOpen, } from "../redux/executiveReducer";
import { useDispatch } from "react-redux";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { CSVLink } from 'react-csv'
import { Checkbox } from "primereact/checkbox";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import * as XLSX from 'xlsx';
const AgeingMember = () => {
    const AgeingMemberData = useSelector(state => state.executive.AgeingMemberData)
    const [visible, setVisible] = useState(false);
    const [filterState, setFilterState] = useState(false)
    const [loading, setLoading] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [totalRecords, setTotalRecords] = useState(null)
    const globalFilterFields = [
        "state",
        "estate",
        "memberid",
        "membername",
        "oldicno",
        "newicno",
        "race",
        "age",
        "sex",
        "total",
        "jan",
        "feb",
        "mar",
        "apr",
        "may",
        "jun",
        "jul",
        "aug",
        "sep",
        "oct",
        "nov",
        "dec"
    ]
    const modalOpen = useSelector(state => state.executive.modalOpen)
    const [arrangeTable, setArrangeTable] = useState(false)
    const dispatch = useDispatch()
    const [exportCSV, setExportCSV] = useState([])
    const [seperatedColumn, setSeperatedColumn] = useState([])
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [selectedColumn,setSelectedColumn]=useState([])
    const formPayload = useSelector(state=>state.executive.formPayload)
    let month_arr = ["jan","feb","mar","apr","may","jun","jul","aug","sep","oct","nov","dec"]
    const isSidebarOpen = useSelector((state) => state.executive.open);
    const CustomFilter = (options) => {

        return (
            <InputText style={{ width: "182px", height: "36px" }} placeholder="search" value={options.value} onChange={(e) => options.filterApplyCallback(e.target.value,)} />
        );
    };


    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => { options.filterApplyCallback(e.target.value); }} dateFormat="dd/mm/yyyy" placeholder="dd/mm/yyyy" />;
    };

    const dateOfJoinBody = (rowData) => {
        const originalDate = new Date(rowData.doj);
        const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = originalDate.getDate().toString().padStart(2, '0');
        const year = originalDate.getFullYear();

        const formattedDate = `${day}-${month}-${year}`

        return formattedDate;
    }
    const onColumnChange = (e) => {
        let column=[...columnVisible]
        let _selectedCategories = [...seperatedColumn];
        if(e.checked){
            //console.log(e.value)
            let deselect=column.filter(item=>item.header==e.value.header).map(item=>{item.visible=true;return item})
           // console.log(column)
            deselect=[ ...column,...deselect];
            deselect=[...new Set(deselect)]
            //console.log(deselect)
            setSelectedColumn(deselect)
            _selectedCategories.push(e.value);
        }
        else{
            //console.log(e.value)
            let deselect=column.filter(item=>item.header==e.value.header).map(item=>{item.visible=false;return item})
            //console.log(column)
            deselect=[ ...column,...deselect];
            deselect=[...new Set(deselect)]
            //console.log(deselect)
            setSelectedColumn(deselect)
            _selectedCategories = _selectedCategories.filter(category => category.header !== e.value.header);
            

        }
        setSeperatedColumn(_selectedCategories)

    };
    const applyFilter=()=>{
        let columns=[...columnVisible]

        
        // columns=columns.map(column=>{
        //     selectedColumn.some(item=>)
        //     // console.log(array)
        //     return column
            
        // })
        //console.log(columns)
        //console.log(selectedColumn)

        setColumneVisible(selectedColumn)
        setArrangeTable(false)
        let exportHeaders=[...exportCSV]
        //console.log(exportHeaders)
        exportHeaders = selectedColumn.filter((item)=>item.visible).map(item=>{ return {label:item.header,key:item.field}})
        //console.log(exportHeaders)

        setExportCSV(exportHeaders)
    }
    const calculateAge = (e)=>{
        //console.log("------",e.dob)
        let dob = e.dob
        const birthDate = new Date(dob);
        const currentDate = new Date();

        // Calculate age
        let age = currentDate.getFullYear() - birthDate.getFullYear();
        //console.log(dob,age)
        // Adjust age if the birthday hasn't occurred yet this year
        if (
        currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() &&
            currentDate.getDate() < birthDate.getDate())
        ) {
        age -= 1;
        }
        if (age < 0){
            age = 0
        }
        return age;
    }
    const rowTotal = (rowdata,rowIndex)=>{
       let filteredMonths = month_arr.filter(e=> Object.keys(rowdata).includes(e))
       if(filteredMonths.length>1){
        let total = 0
           filteredMonths.map((acc)=> total+=parseFloat(rowdata[acc]))
           
           return parseFloat(total).toFixed(2)
        }else{
           return parseFloat(rowdata[filteredMonths[0]]).toFixed(2)
       }
    }
    const [columnVisible, setColumneVisible] = useState([
        { field: "state", sortable: true, header: "State", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        { field: "estate", sortable: true, header: "Estate Name", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        { field: "memberid", header: "M #", sortable: true, filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        { field: "membername", sortable: true, header: "MemberName", filterField: "membername", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        { field: "oldicno", sortable: true, header: "NRIC Old", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        { field: "newicno",  sortable: true, filter: true, header: "NRIC New", filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        { field: "race", sortable: true, header: "Race", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        { field: "age", sortable: true, header: "Age", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        { field: "sex", sortable: true, header: "Sex", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        // { field: formPayload.option ==="share"?"sharebalance":formPayload.option ==="subscription"?"subscriptionbalance":"loanbalance", 
        // header: formPayload.option ==="share"?"Share":formPayload.option ==="subscription"?"Subscription":"Loan", 
        // sortable: true, filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        { field: "total", header: "Total",body:rowTotal, sortable: true, filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        { field: "jan", header: "Jan", sortable: true, filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true,body:(e)=>!(isNaN(e.jan) || e.jan == null)?parseFloat(e.jan).toFixed(2):"0.00"},
        { field: "feb", header: "Feb", sortable: true, filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true,body:(e)=>!(isNaN(e.feb) || e.feb == null)?parseFloat(e.feb).toFixed(2):"0.00"},
        { field: "mar", header: "Mar", sortable: true, filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true,body:(e)=>!(isNaN(e.mar) || e.mar == null)?parseFloat(e.mar).toFixed(2):"0.00"},
        { field: "apr", header: "Apr", sortable: true, filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true,body:(e)=>!(isNaN(e.apr) || e.apr == null)?parseFloat(e.apr).toFixed(2):"0.00"},
        { field: "may", header: "May", sortable: true, filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true,body:(e)=>!(isNaN(e.may) || e.may == null)?parseFloat(e.may).toFixed(2):"0.00"},
        { field: "jun", header: "Jun", sortable: true, filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true,body:(e)=>!(isNaN(e.jun) || e.jun == null)?parseFloat(e.jun).toFixed(2):"0.00"},
        { field: "jul", header: "Jul", sortable: true, filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true,body:(e)=>!(isNaN(e.jul) || e.jul == null)?parseFloat(e.jul).toFixed(2):"0.00"},
        { field: "aug", header: "Aug", sortable: true, filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true,body:(e)=>!(isNaN(e.aug) || e.aug == null)?parseFloat(e.aug).toFixed(2):"0.00"},
        { field: "sep", header: "Sep", sortable: true, filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true,body:(e)=>!(isNaN(e.sep) || e.sep == null)?parseFloat(e.sep).toFixed(2):"0.00"},
        { field: "oct", header: "Oct", sortable: true, filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true,body:(e)=>!(isNaN(e.oct) || e.oct == null)?parseFloat(e.oct).toFixed(2):"0.00"},
        { field: "nov", header: "Nov", sortable: true, filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true,body:(e)=>!(isNaN(e.nov) || e.nov == null)?parseFloat(e.nov).toFixed(2):"0.00"},
        { field: "dec", header: "Dec", sortable: true, filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true,body:(e)=>!(isNaN(e.dec) || e.dec == null)?parseFloat(e.dec).toFixed(2):"0.00"},
        
        // { field: "subscriptionbalance", sortable: true, header: "Subscription", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        
        // { field: "doj", sortable: true, header: "Date Joined", filter: true, filterPlaceholder: "Search", filterElement: dateFilterTemplate , visible: true,body:dateOfJoinBody,filterField:"doj",frozen:true},
        // { field: "region", body: (e) => e.oldicno ? e.oldicno : "Null", sortable: true, header: "Region", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        // { field: "postalcode", sortable: true, header: "Post Code", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        // { field: "photo", sortable: true, header: "Photo", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        // { field: "mykadnominee", sortable: true, header: "Nominee MyKad", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        // { field: "sdbalance", header: "SD", sortable: true, filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        // { field: "brbalance", header: "Borrowings", sortable: true, filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        // { field: "representative.name", header: "Bonus", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        // { field: "nomail", header: "No Mailer", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        // { field: "deceased", header: "Deceased", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        // { field: "disciplinaryaction", header: "Displ.Action", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        // { field: "director", header: "Director", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        // { field: "observer", header: "Observer", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        // { field: "delegates", header: "Delegate", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        // { field: "company", header: "Company", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        // { field: "junior", header: "Junior", filter: true, filterPlaceholder: "Search" , visible: true,filterElement:CustomFilter,frozen:true},
        // { field: "terminated", header: "Terminated", filter: true, filterPlaceholder: "Search", visible: true,filterElement:CustomFilter,frozen:true}
    ])
    const clearFilter = () => {
        let filter_obj = {}
        filter_obj['global'] = { value: null, matchMode: FilterMatchMode.CONTAINS }
        columnVisible.map((obj)=>{
            filter_obj[obj.field] = { value: null, matchMode: FilterMatchMode.CONTAINS }
        })
        setFilters(filter_obj)
        setGlobalFilterValue('')
        // setFilters(
        //     {
        //         global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         membername: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         sex: { value: null, matchMode: FilterMatchMode.IN },
        //         estate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         state: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         share: { value: null, matchMode: FilterMatchMode.EQUALS },
        //         subscription: { value: null, matchMode: FilterMatchMode.EQUALS },
        //         memberid: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         photo: { value: null, matchMode: FilterMatchMode.IN },
        //         race: { value: null, matchMode: FilterMatchMode.IN },
        //         doj: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         age:{value:null, matchMode: FilterMatchMode.CONTAINS},
        //         newicno:{value:null, matchMode: FilterMatchMode.CONTAINS},
        //         oldicno:{value:null, matchMode: FilterMatchMode.CONTAINS},

        //     }
        // )
    }


    const [filters, setFilters] = useState(()=>{
        let filter_obj = {}
        filter_obj['global'] = { value: null, matchMode: FilterMatchMode.CONTAINS }
        columnVisible.map((obj)=>{
            filter_obj[obj.field] = { value: null, matchMode: FilterMatchMode.CONTAINS }
        })
        return filter_obj
    }
    //     {
    //     global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     membername: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     sex: { value: null, matchMode: FilterMatchMode.IN },
    //     estate: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     state: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     share: { value: null, matchMode: FilterMatchMode.EQUALS },
    //     subscription: { value: null, matchMode: FilterMatchMode.EQUALS },
    //     memberid: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     photo: { value: null, matchMode: FilterMatchMode.IN },
    //     race: { value: null, matchMode: FilterMatchMode.IN },
    //     doj: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     age:{value:null, matchMode: FilterMatchMode.CONTAINS},
    //     newicno:{value:null, matchMode: FilterMatchMode.CONTAINS},
    //     oldicno:{value:null, matchMode: FilterMatchMode.CONTAINS},

    // }
    );
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        //console.log(_filters)

        _filters['global'].value = value;
        //console.log(_filters)

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {

            setSelectAll(true);
            setSelectedCustomers(AgeingMemberData);

        } else {
            setSelectAll(false);
            setSelectedCustomers([]);
        }
    };
    const onSelectionChange = (event) => {
        const value = event.value;
        //console.log(value)
        setSelectedCustomers(value);
        //console.log(selectedCustomers)
        setSelectAll(value.length === totalRecords);
    };

    const addCurrencyAndCommafy = (num) => {

        let str = parseFloat(num).toFixed(2).toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        return "RM " + str.join('.');

    }
    
    const calculateTotal = (data,mon)=>{
        
        
        let total_obj = []
        AgeingMemberData.filter(e=>e.state === data.state).map((obj,i)=>{
            if(!total_obj[mon]){
                total_obj[mon] = parseFloat(obj[mon])
            }
            else if(total_obj[mon] && obj[mon]){
                total_obj[mon] = total_obj[mon] + parseFloat(obj[mon])
            }
            
        })
        return total_obj
        
    }
    const footerTemplate = (data) => {
        let new_pos=columnVisible.filter(item=>item.visible).map(item=>(item.field))
        let total_obj = []
        month_arr.map(e=>total_obj[e] = calculateTotal(data,e)[e])
        let valued_months = Object.keys(total_obj).filter(e=>total_obj[e])
        return (
            <>
                <td colSpan={2}>
                    <Typography style={{fontWeight:700,textTransform:"capitalize"}}>State {data.state} Total :</Typography>
                </td>
                {
                    month_arr.map((e,i)=>{
                        
                        return(
                            total_obj[e]?
                            // <td colSpan={`${prev_total <=0?valued_months.indexOf(e)>0?new_pos.indexOf(e) - new_pos.indexOf(valued_months.indexOf(e) - 1):new_pos.indexOf(e):1}`}>
                            <td colSpan={`${total_obj[month_arr[i-1]]? 1: total_obj[valued_months[valued_months.indexOf(e) - 1]]? new_pos.indexOf(e) - new_pos.indexOf(valued_months[valued_months.indexOf(e) - 1])  : new_pos.indexOf(e)}`}>
                                <Typography sx={{display:"flex",justifyContent:"end",width:"100%",fontWeight:600,textTransform:"capitalize"}}>{e} : {addCurrencyAndCommafy(total_obj[e])}</Typography>
                            </td>
                            : null
                        )
                    })
                }
            </>
        );
    };
    const formattingdate=(date)=>{
        const originalDate = new Date(date);
        const year = String(originalDate.getUTCFullYear()).slice(2,4);
        const month = String(originalDate.getUTCMonth() + 1).padStart(2, '0');
        const day = String(originalDate.getUTCDate()).padStart(2, '0');
    
        let formattedDate = `${day}-${month}-${year}`;
        return formattedDate;

    }
    useEffect(() => {
        //console.log("---inside NMC---", AgeingMemberData)
        // let finalOutput = [];
        // let stateKeyCounter = 0;

        // for (let obj of AgeingMemberData) {
        //     let state = obj['state'];
        //     let estate = obj['estate'];
        //     let remainingObj = Object.fromEntries(Object.entries(obj).filter(([key]) => !['state', 'estate'].includes(key)));

        //     // Check if 'key' is present in remainingObj
        //     let keyValue = remainingObj['key'];
        //     delete remainingObj['key'];

        //     // Check if the state is already in the finalOutput
        //     let stateExists = finalOutput.find(item => item.data.state === state);

        //     if (stateExists) {
        //         // State exists, check if estate exists
        //         let estateExists = stateExists.children.find(item => item.data.estate === estate);

        //         if (estateExists) {
        //             // Estate exists, add remainingObj to children
        //             let estateChildKey = `${estateExists.key}-${estateExists.children.length}`;
        //             estateExists.children.push({
        //                 key: estateChildKey,
        //                 data: remainingObj
        //             });
        //         } else {
        //             // Estate does not exist, add estate and remainingObj
        //             let stateKey = stateExists.key;
        //             let estateData = {
        //                 key: `${stateKey}-${stateExists.children.length}`,
        //                 data: { estate: estate },
        //                 children: [{ key: `${stateKey}-${stateExists.children.length}-0`, data: remainingObj }]
        //             };
        //             stateExists.children.push(estateData);
        //         }
        //     } else {
        //         // State does not exist, add state, estate, and remainingObj
        //         let stateKey = stateKeyCounter.toString();
        //         let stateData = {
        //             key: stateKey,
        //             data: { state: state },
        //             children: [{
        //                 key: `${stateKey}-0`,
        //                 data: { estate: estate },
        //                 children: [{ key: `${stateKey}-0-0`, data: remainingObj }]
        //             }]
        //         };
        //         finalOutput.push(stateData);
        //         stateKeyCounter++;
        //     }
        // }
        // setFormattedAgeingData(finalOutput)

    }, [AgeingMemberData])
    const SubHeaderTemplate = (e)=>{
        return (
            <Box>
                <Typography sx={{fontWeight:"bold"}}>State : {e.state}</Typography>
            </Box>
        );
    }
    let GrandTotal_obj = {}
    const GrandTotal = (mon)=>{
        
        let total = 0
        let filteredMonths = AgeingMemberData.filter(obj=>Object.keys(obj).includes(mon))
    
        if(filteredMonths[0]){

            filteredMonths.map(obj=>total += parseFloat(obj[mon]))
        }
        if(mon === "total"){
            AgeingMemberData.forEach(obj => {
                month_arr.forEach(mon=>{
                    if(Object.keys(obj).includes(mon)){
                        if(!GrandTotal_obj["total"]) GrandTotal_obj["total"] = 0
                        GrandTotal_obj["total"] += parseFloat(obj[mon])
                    }
                })
            });
            return GrandTotal_obj["total"]
        }
        else{

            return total
        }

    }
    const ColumnFooterTotal = (
        <ColumnGroup>
            <Row>
                <Column footer="Grand Total : " colSpan={2}
                // colSpan={columnVisible.filter(obj=>obj.visible).map(obj=>obj.field).indexOf("jan") < 1?1:columnVisible.filter(obj=>obj.visible).map(obj=>obj.field).indexOf("jan") + 1} 
                ></Column>
                {
                    ["total",...month_arr].map((mon,i)=>{
                        GrandTotal_obj[mon] = GrandTotal(mon)
                        let valued_months = Object.keys(GrandTotal_obj).filter(key=>GrandTotal_obj[key])
                        // console.error(valued_months)
                        // console.error("---------after total-------------",mon)
                        let prev_total  = GrandTotal(month_arr[month_arr.indexOf[mon] - 1])
                        // console.log("------------totonb--------",mon,month_arr[i],prev_total)
                        let new_pos = columnVisible.filter(obj=>obj.visible).map(obj=>obj.field)
                        // if(prev_total<=0 && valued_months.indexOf(mon)>0){
                        //     console.log(new_pos.indexOf(mon) - new_pos.indexOf(valued_months[valued_months.indexOf(mon) - 1]))
                        // }
                        return(
                            GrandTotal_obj[mon]>=1&&new_pos.includes(mon)&&<Column
                            colSpan={
                              prev_total <= 0
                                ? valued_months.indexOf(mon) > 0
                                  ? new_pos.indexOf(mon) - new_pos.indexOf(valued_months[valued_months.indexOf(mon) - 1])
                                  : new_pos.indexOf(mon)
                                : 1
                            }
                            footer={
                              <span>
                                <span style={{ fontWeight: 'bold' }}>{mon} : </span>{' '}
                                {addCurrencyAndCommafy(GrandTotal_obj[mon])}
                              </span>
                            }
                            footerStyle={{ textTransform: 'capitalize', textAlign: 'end' ,fontWeight: 'normal'}}
                          />
                          
                        )
                    })
                }

            </Row>
        </ColumnGroup>
    )

    const exportExcel = (data) => {
        data = data.map((item, index) => {
            return {  SN: index + 1, ...item };
          });
          let filteredData
          let headers
          let requiredColumns

          let additionalRow = []

          if(formPayload['AM'].option=="share" || formPayload['AM'].option=="subscription"){
            requiredColumns = ['SN','memberid','membername','newicno', 'oldicno','race','age','sex','total','jan','feb','mar','apr','may','jun','jul','aug','sep','oct','nov','dec','state','estate'];

            const columnHeadersMap = {
                SN: 'SN',
                memberid: 'M#',
                membername: 'Member Name',
                newicno: 'NRIC New',
                oldicno:'NRIC Old',
                race: 'Race',
                age:"Age",
                sex:"Sex",
                total:"Total",
                jan:"Jan",
                feb:"Feb",
                mar:"Mar",
                apr:"Apr",
                may:"May",
                jun:"Jun",
                jul:"Jul",
                aug:"Aug",
                sep:"Sep",
                oct:"Oct",
                nov:"Nov",
                dec:"Dec",
                state:"State",
                estate:"Estate"
              };
                 filteredData = data.map(obj => {
                  return requiredColumns.reduce((acc, key) => {
                    acc[key] = obj[key];
                    return acc;
                  }, {});
                }); 
            headers = requiredColumns.map(header => {
              return { 
                  v: columnHeadersMap[header], 
                  t: 's', 
                  s: { 
                      font: { bold: true,size: 10 } } };
            });

            additionalRow = [['','KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)'],
                             [],
                             ['',`Member Ageing Report - ${formPayload['AM'].option=="share"?"Share":"Subscription"}`],
                             ['','Sort Order :'],
                             ['',`Date Range for the Period : ${formattingdate(formPayload['AM'].dr[0])+ " TO " +formattingdate(formPayload['AM'].dr[1])}`],
                             ['',`Report Date : ${formattingdate(new Date())}`],
                             [],[],[],[]]
          }
         else{
            requiredColumns = ['SN','memberid','loanno','membername','loanexpirydate','exp','newicno','state','total','jan','feb','mar','apr','may','jun','jul','aug','sep','oct','nov','dec','loanbalanceamount'];

              const columnHeadersMap = {
                  SN: 'SN',
                  memberid: 'M#',
                  state: 'State',
                  loanno:'loanID',
                  membername: 'MemberName',
                  loanexpirydate: 'LoanExpiryDate',
                  exp: 'Exp',
                  newicno:"NewIcNo",
                  state:"state",
                  total:"Total",
                  jan:"Jan",
                  feb:"Feb",
                  mar:"Mar",
                  apr:"Apr",
                  may:"May",
                  jun:"Jun",
                  jul:"Jul",
                  aug:"Aug",
                  sep:"Sep",
                  oct:"Oct",
                  nov:"Nov",
                  dec:"Dec",
                  
                };
                 filteredData = data.map(obj => {
                  return requiredColumns.reduce((acc, key) => {
                    acc[key] = obj[key];
                    return acc;
                  }, {});
                }); 
             headers = requiredColumns.map(header => {
              return { 
                  v: columnHeadersMap[header], 
                  t: 's', 
                  s: { 
                      font: { bold: true,size: 10 } } };
            });

              additionalRow = [['','KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)'],
                               [],
                               ['',`Member Ageing Report - Loan`],
                               ['','Sort Order :'],
                               ['',`Date Range for the Period : ${formattingdate(formPayload['AM'].dr[0])+ " TO " +formattingdate(formPayload['AM'].dr[1])}`],
                               ['', `Membership No. : ${formPayload['AM'].mid.length === 0?" - Not Selected - ": formPayload['AM'].mid[0]+" TO "+formPayload['AM'].mid[1]}`],
                               ['', `Share Balance Range : ${formPayload['AM'].sh.length === 0?" - Not Selected - ": formPayload['AM'].sh[0]+" TO "+formPayload['AM'].sh[1]}`],
                               ['', `Subscription Balance Range : ${formPayload['AM'].sb.length === 0?" - Not Selected - ": formPayload['AM'].sb[0]+" TO "+formPayload['AM'].sb[1]}`],
                               ['', `Age : ${formPayload['AM'].age.length === 0?" - Not Selected - ": formPayload['AM'].age[0]+" TO "+formPayload['AM'].age[1]}`],
                               ['', `Sex : ${formPayload['AM'].sex === ""?"ALL": formPayload['AM'].sex}`],
                               ['', `Race : ${formPayload['AM'].race === ""?"ALL": formPayload['AM'].race}`],
                               ['',`Report Date : ${formattingdate(new Date())}`],
                               [],[],[],[]]
          }
    
        // Convert array of objects to array of arrays (2D array)
        const dataArray = [...additionalRow, headers]
            filteredData.forEach(obj => {
               if(formPayload['AM'].option=="loan"){
                if(parseInt(obj.loanbalanceamount) <= 0){
                obj.exp = 'F'
               } else if (new Date(obj.loanexpirydate)< new Date()){
                obj.exp = 'X'
               }else{
                obj.exp = ''
               }}
                let total = 0;
                for (let key in obj) {
                  if (month_arr.includes(key.toLowerCase())) {
                    if (obj[key] !== undefined) {
                      total += parseInt(obj[key]);
                    } else {
                        obj[key] = 0..toFixed(2);
                    }
                  }
                }
                obj.total = total.toFixed(2); 
                const rowData = requiredColumns.map(key => {
                    let value;
                    const amountRelatedKeys = ['total','jan','feb','mar','apr','may','jun','jul','aug','sep','oct','nov','dec','loanbalanceamount'];
                    if(amountRelatedKeys.includes(key)){
                        value = parseInt(parseFloat(obj[key]).toFixed(2).split('.')[1]) === 0?parseInt(obj[key]):obj[key]
                    }
                    else if(key === 'loanexpirydate')
                        value = formattingdate(obj[key])
                    else
                        value = obj[key]
                  return {
                    v: value,
                    t: typeof value === 'number' ? 'n' : 's',
                    s: {
                      font: { size: 8, name: 'Times New Roman', bold: key === requiredColumns[0] }
                    }
                  };
                });
              
                dataArray.push(rowData);
              });
              if(formPayload['AM'].option == 'loan'){
              const columnTotals = {};
              let sumOfTotal = 0
              filteredData.forEach(obj => {
                sumOfTotal += parseInt(obj.total);
                month_arr.forEach(mon => {
                  if (obj.hasOwnProperty(mon)||obj.total) {
                    columnTotals[mon] = (parseInt(columnTotals[mon]) || 0) + parseInt(obj[mon]);
                  }
                });
              });
              let grandTotal = 0;
              month_arr.forEach(mon => {
                grandTotal += columnTotals[mon] || 0;
              });
              
              const grandTotalRow = month_arr.map(mon => ({ v: columnTotals[mon] || 0, t: 'n' }));
              grandTotalRow.unshift({ v: 'Grand Total', t: 's' },{v:""},{v:""},{v:""},{v:""},{v:""},{v:""},{v:""},{v:sumOfTotal});

              dataArray.push([],grandTotalRow,['Print Date :',formattingdate(new Date())]);}
        const ws = XLSX.utils.aoa_to_sheet(dataArray);
    
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
        XLSX.writeFile(wb, `Member Ageing Report - ${formPayload['AM'].option=="loan"?"Loan":formPayload['AM'].option=="share"?"Share":"Subscription"}.xlsx`);
    }
    return (
        <Box className="fulldiv" sx={{ width: isSidebarOpen?"calc(100vw - 319px)":"calc(100vw - 95px)", height: "calc(100vh - 64px)", }} >
            <Dialog visible={modalOpen} style={{ width: '790px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={false} closable={false} resizable={false} >
                <Form formAlone={true} api="AM" />
            </Dialog>
            <Box sx={{ marginLeft: "24px", padding: "8px 0px 8px 0px", marginRight: "16px", height: "93vh", }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", gap: "12px", }}>
                        <SummarizeOutlinedIcon sx={{ fontSize: "32px", color: "#288d2c" }} />
                        <Box>
                            <Typography sx={{ fontSize: "20px", fontWeight: "600", lineHeight: "28px", color: "#101010",textTransform:"capitalize" }}>Ageing Member - {formPayload['AM']?.option} Report</Typography>
                            <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#64748B" }}>Found <span style={{ fontWeight: "700" }}>{AgeingMemberData.length}</span> records from <span style={{ fontWeight: "700" }}>{formattingdate(formPayload['AM']?.dr[0])}</span> to <span style={{ fontWeight: "700" }}>{formattingdate(formPayload['AM']?.dr[1])}</span> with applied Configure</Typography>
                        </Box>

                    </Box>
                    <Box sx={{ alignSelf: "end" }}>
                        <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#288d2c" }, gap: "8px", fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "201px", height: "48px" }} startIcon={<SettingsIcon sx={{ fontSize: "24px", }} />} onClick={() => dispatch(updateModalOpen(true))}>Data Re-Configure</Button>
                    </Box>

                </Box>
                <Box sx={{ marginTop: "24px",  }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between",width:"100%",flexWrap:"wrap" }}>


                        <Box sx={{ display: "flex", alignItems: "center" }}>

                            <Box>

                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText style={{ width: "655px", height: "36px" }} placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} />
                                </span>
                            </Box>
                            <Box sx={{ color: "#64748B", width: "178px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "16px", alignSelf: "end", cursor: "pointer", textTransform: "none", gap: "8px" }} onClick={clearFilter}>
                                <ClearAllIcon sx={{ fontSize: "24px", color: "#CBD5E1" }} />
                                <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#CBD5E1" }}></Typography>
                                Clear Filter
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "16px", alignSelf: 'end' }}>
                            <Box sx={{ padding: "12px" }}> {filterState ? <FilterAltOffIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(false)} /> : <FilterAltIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(true)} />}</Box>

                            <Box sx={{ padding: "12px" }}><DownloadIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={()=>exportExcel(AgeingMemberData)} /> </Box>
                            <Box sx={{ padding: "12px" }}><TableChartIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => { setArrangeTable(true); setSeperatedColumn(columnVisible) }} /> </Box>

                        </Box>

                    </Box>

                </Box>
                <Box sx={{ marginTop: "24px", }}>
                    <div className="card">
                            <DataTable value={AgeingMemberData} filters={filters} paginator rows={4} dataKey="memberid" style={{ backgroundColor: "#F1F5F9" }} filterDisplay={filterState ? "row" : ""} loading={loading} columnResizeMode="expand" removableSort resizableColumns 
                                rowGroupMode="subheader" groupRowsBy="state" rowGroupHeaderTemplate={(e)=>SubHeaderTemplate(e)}
                                selection={selectedCustomers} selectionMode="checkbox" onSelectionChange={onSelectionChange} selectAll={selectAll} filterIcon={false} onSelectAllChange={onSelectAllChange} emptyMessage="No customers found." globalFilterFields={globalFilterFields}
                                rowGroupFooterTemplate={(e)=>footerTemplate(e)} 
                                footerColumnGroup={ColumnFooterTotal}
                                // footer={(e)=>TableFooter(e)}
                                >
                                <Column styleClass="custom-column-style" selectionMode="multiple" headerStyle={{ width: "100px", background: "#F1F5F9" }} />

                                {columnVisible.filter(col => col.visible).map(col => <Column className="custom-column-style" style={{ width: "500px" }}  field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                            </DataTable>
                    </div>
                </Box>


            </Box>
            <Dialog visible={arrangeTable} style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={true} closable={false} resizable={false} >


                <Box style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", padding: "30px 30px 0px 30px", borderRadius: "0px", display: "flex", flexDirection: "column", gap: "24px", }}>
                    <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#f2f6fc" }}>
                        <TableChartIcon sx={{ fontSize: "24px", color: "#288d2c" }} />
                        <Typography sx={{ fontSize: "18px", fontWeight: 600, lineHeight: "28px" }}>Table Column Configure</Typography>
                    </Box>
                    <Box>


                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText style={{ width: "461px", height: "36px" }} placeholder="Search" onChange={onGlobalFilterChange} />
                        </span>

                    </Box>
                    <Box sx={{ height: "461px", overflowY: "scroll",display:"flex",flexDirection:"column",gap:"20px" }}>
                        
                                {columnVisible.map((column, ind) => {
                                    return (

                                        <div key={column.header}  style={{gap:"8px",display:"flex",alignItems:"center"}}>
                                            <Checkbox inputId={column.header}  value={column} onChange={onColumnChange} disabled={column.header=="Member #" || column.header=="MemberName"} checked={column.visible} />
                                            <label htmlFor={column.header} className="ml-2">
                                                {column.header}
                                            </label>
                                        </div>

                                    );
                                })}
                          
                    </Box>
                    <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#F1F5F9", paddingTop: "24px", paddingBottom: "24px", justifyContent: "space-between", marginLeft: "-30px", marginRight: "-30px", }}>

                        <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#288d2c" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "138px", height: "48px", marginLeft: "30px" }} startIcon={<CheckCircleOutlineIcon />} onClick={applyFilter} >Apply</Button>
                        <Button variant="outlined" sx={{ borderColor: "#64748B", color: "#64748B", "&:hover": { borderColor: "#64748B" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", marginRight: "30px" }} startIcon={<ClearIcon sx={{ color: "#64748B", fontSize: "24px" }}  />} onClick={() => setArrangeTable(false)}>Clear</Button>


                    </Box>
                </Box>


            </Dialog >

        </Box>
    )
}


export default AgeingMember