import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import MuiAutocomplete from "../elements/Mui/mui_Autocomplete";
import MuiInput from "../elements/Mui/mui_input";
import MuiDatePicker from "../elements/Mui/mui_date_picker";
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { useMediaQuery } from "react-responsive";
import ClearIcon from '@mui/icons-material/Clear';
import CustomAxios from "../utils/CustomAxios";

import MuiButton from "../elements/Mui/mui_button";
import * as XLSX from 'xlsx';
import moment from 'moment';
import MuiInputAmount from "../elements/Mui/mui_input_amount";

export function Dividend() {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const [Dividend, setDividend] = useState("New Dividend")
  const [banks, setBanks] = useState([])
  const [excelfilename, setExcelFilename] = useState(null)
  const [coopbanks, setCoopBanks] = useState([])
  const [bank, setBank] = useState(null)
  const [cleareddialogbox, setClearedDialogBox] = useState(false)
  const [downloadexcel, setDownloadExcel] = useState(false)
  const [coopbank, setCoopBank] = useState(null)
  const [visible, setVisible] = useState(false);
  const [withdrawalnaration,setWithdrawalNarration] = useState('')
  const [firstchequeno, setFirstChequeNo] = useState(0)
  const [save, setSave] = useState(false);
  const [postdiv, setPostDiv] = useState(false);
  const [postdivwithdrawal, setPostDivWithdrawal] = useState(false);
  const [ccdate, setCcDate] = useState(null);
  const [bulkamt, setBulkAmt] = useState(null)
  const [chequeboxdialog, setChequeBoxDialog] = useState(false);
  const [postdialag, setPostDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [recordtype, setRecordType] = useState("share")
  const [bulkno, setBulkno] = useState(null)
  const [chequecleared, setChequeCleared] = useState(false)
  const [cdatefound, setCDateFound] = useState(false)
  const [dividenPercentage, setDividendPercentage] = useState("");
  const [postnarration, setPostNarration] = useState(new Date().getFullYear() + " " + "Bonus " + recordtype + " " + dividenPercentage + "%")
  const [selectedYear, setSelectedYear] = useState("");
  const [transdate, setTransDate] = useState(null);
  const [states, setStates] = useState([]);
  const [chequestatus, setChequeStatus] = useState(null);
  const [state, setState] = useState(null);
  const [savebutton, setSaveButton] = useState(true)
  const [exceltype, setExcelType] = useState(true)
  const [begindate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minShare, setMinshare] = useState("");
  const [minSubs, setMinSubs] = useState("");
  const currYear = new Date().getFullYear();
  const [narration, setNarration] = useState("Dividend Generated");
  const [divnarrations, setDivNarrations] = useState([])
  const [dividendgen, setDividendGen] = useState([])
  const [cleareddata, setClearedData] = useState([])
  const fileInputRef = useRef(null);
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  let allchequestatus = ["All","Not Cleared","Cleared","Cheque Cancelled"]
  const [chqClearedDateExcel, setChqClearedDateExcel] = useState([])
  const minShBalanceRef=useRef(null)
  const minSubsBalanceRef=useRef(null)

  const currentYear = new Date().getFullYear();
  const yearOptons = Array.from(
    { length: currentYear - 2009 },
    (_, index) => 2010 + index
  );

  useEffect(()=>{
    const gettingStates = async () => {
        const finalMembers = await CustomAxios.get('oasis/form_details/').then((data) => {
            setBanks(data.data.bankName)
            // setestateArray(data.data.estateList)
            const states = data.data.stateList.map((state) => state.statename);
            setDivNarrations(data.data.div_narrations)
            setStates(states)
            setStates([...states, "All"])
            setCoopBanks(data.data.coopBankname)
        })
    }
    gettingStates()
   
},[])

  const handleFormattedAmount = amount => amount?.toString().replace(/[^0-9.]/g, '')
  
  const handleDividendChange = (e, div)=>{
    e.preventDefault();
    setError("")
    setTransDate(null)
    setBeginDate(null)
    setEndDate(null)
    setNarration(div=="New Dividend"?'Dividend Generated':'')
    setMinshare(null)
    setMinSubs(null)
    setState(null)
    setSelectedOption(null)
    setDividendPercentage(null)
    setDividend(div)

  }
  const formatTodayDate = () => {
    const date = new Date()
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = date.getDate();
    const month = String(date.getMonth()+1).padStart(2,'0');
    // const month = months[monthIndex];
    const year = date.getFullYear();
    // const hours = date.getHours();
    // const minutes = date.getMinutes();
    // const seconds = date.getSeconds();
    //console.log(`${day}/${month}/${year} ${hours}:${minutes} ${seconds}`)
    return `${day}-${month}-${year}`;
};

  const clearform = (e) => { 
    window.location.reload();  
    // e.preventDefault()
    // setDividendGen([])
    // setMinshare(null)
    // setMinSubs(null)
    // setTransDate(null)
    // setBeginDate(null)
    // setEndDate(null)
    // setDividendPercentage(null)
    // setCoopBank(null)
    // setBulkAmt(null)
    // setCcDate(null)
    // setSave(false)
    // setPostDialog(false)
    // setChequeBoxDialog(false)
    // setLoading(false)
    // setPostDiv(false)
    // setPostNarration(null)
    // setFirstChequeNo(null)
    // setChequeCleared(false)
    // setState(null)
    // setChequeStatus(null)
    // setNarration(null)
    // setSaveButton(true)
    // setDividend("New Dividend")
  }

  const exportExcel = () => {
    setDownloadExcel(false)
    import('xlsx').then((xlsx) => {
      let rearragedData
      if(exceltype=="currdiv"){
        rearragedData = dividendgen.map((item,index)=>({
          S_No: index + 1,
          Bank:item.bank,
          Cheque_No:item.chequno,
          Cleared_date:item.clearedDate,
          M_No:item.memberid,
          Member_Name:item.membername,
          DBS_Deduct:item.dbs,
          Cheque_Amount:item.total_rounded_dividend - item.dbs,
          Total_Rounded_Dividend:item.total_rounded_dividend,
          State:item.state,
          Bulk_No:item.bulk_no,
          PV_No:item.pv_no,
        }))
      }
      else if (exceltype=="gendiv"){
      rearragedData = dividendgen.map((item,index)=>{
        
      const loans = item.loanList.reduce((acc, { id, loanbalance }, index) => {
        acc[`Loan_${index + 1}`] = id;
        acc[`Loan_${index + 1}_Balance`] = loanbalance;
        return acc;
      }, {});

        return ({
        S_No: index + 1,
        MNo:item.memberid,
        Name:item.membername,
        Bank_Name:item.bank,
        Cheque_Bo:item.chequno,
        Cheque_Cleared_Date: item.clearedDate,
        Share_Bal_Beginning:item.beginingsharebalance,
        Share_Bal_Inbetween:item.inbetweesharebalance,
        Share_Bal_End:item.endsharebalance,
        Share_Bal_Selected:item.selected_share_balance,
        Share_Bal_Rounded:item.share_rounded,
        Share_Dividend:item.share_dividend,
        Subs_Bal_Beginning:item.beginingsubsbalance,
        Subs_Bal_Inbetween:item.inbetweesubscritionbalance,
        Subs_Bal_End:item.endsubscriptionbalance,
        Subs_Bal_Selected:item.selected_subs_balance,
        Subs_Bal_Rounded:item.subs_rounded,
        Subs_Dividend:item.subs_dividend,
        Total_Rounded_Balance:item.Total_rounded_balance,
        Total_Dividend:item.total_rounded_dividend,
        DBS_Deduct:item.dbs,
        chq_Amount:item.dbs!==null||item.dbs!==""?item.total_rounded_dividend - item.dbs:item.total_rounded_dividend,
        Deceased:item.deceased,
        State:item.state,
        Estate:item.estate,
        ...loans

        })
      })}
      else{
        const bankCSV = dividendgen.map((item1, index) => {
          const i = banks.findIndex(item2 => item2.bankname?.trim() === item1.membank);
          if (item1.membank!==null){
            return {
            Payment_Type_IBG_PBB_REN: banks[i]?.swiftcode=="PBBEMYKL"?"PBB":"IBG",
            Bene_Account_No: item1.accno,
            BIC: banks[i]?.swiftcode,
            Bene_Full_Name: item1.membername,
            ID_Type_For_Intrabank_IBG_NI_OI_BR_PL_ML_PP_For_Rentas_NI_OI_BR_OT: 'NI',
            Bene_Identification_No: item1.nric?.replace(/-/g, ''),
            Payment_Amount:item1.dbs==40.00? item1.total_rounded_dividend-item1.dbs:item1.total_rounded_dividend,
            Recipient_Reference: item1.narration,
            Other_Payment_Details: item1.memberid,
            Bene_Email: item1.email,
            Bene_Mobile_No: item1.mobileno?.replace(/-/g, ''),
            Joint_Bene_Name: '',
            Joint_Beneficiary_Identification_No: '',
            Joint_ID_Type_For_Intrabank_IBG_NI_OI_BR_PL_ML_PP_For_Rentas_NI_OI_BR_OT: '',
            Email_Content_Line_1: '',
            Email_Content_Line_2: '',
            Email_Content_Line_3: item1.narration,
            Email_Content_Line_4: item1.memberid,
            Email_Content_Line_5: '',
            // You can use `i` here if needed
            // For example, to reference a property from `banks[i]`, you could do:
            // Bene_Account_No: banks[i]?.accountNo || ''
          }};
        });
        const filteredList = bankCSV.filter(item => item !== undefined);
        const arraybdownload = filteredList.reduce((acc, data) => {
          const existingObjIndex = acc.findIndex(item => item?.Other_Payment_Details == data?.Other_Payment_Details);
          if ((Object.keys(existingObjIndex).length !== 0)&&(existingObjIndex !== -1)) {
              acc[existingObjIndex].Payment_Amount = parseInt(acc[existingObjIndex].Payment_Amount)+parseInt(data.Payment_Amount)
          } else {
              acc.push({ ...data }); 
          }
          return acc;
      }, []); 
      let combined_barray = [
          [], // String
          [],
          [],
          [],
          [],
          ["", "", "Payment Date", formatTodayDate()],
          [],
          [],
          // Empty row for spacing
          Object.keys(arraybdownload[0]), // Headers
          ...arraybdownload.map(obj => Object.values(obj)), // Object values
      ];
      rearragedData = combined_barray
      }
        const worksheet = xlsx.utils.json_to_sheet(rearragedData);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        saveAsExcelFile(excelBuffer, exceltype=="gendiv"?'dividend_genarated':exceltype=="currdiv"?'dividend_posted':'pbecp_template');
    });
};

const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
        if (module && module.default) {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });

            module.default.saveAs(data, fileName + EXCEL_EXTENSION);
        }
    });
};


  const [filters, setFilters] = useState({
    memberid:{value:null, matchMode:FilterMatchMode.CONTAINS},
    membername:{value:null, matchMode:FilterMatchMode.CONTAINS},
    state:{value:null, matchMode:FilterMatchMode.CONTAINS},
    estate:{value:null, matchMode:FilterMatchMode.CONTAINS}
})

  const decimaltrb =(rowData)=>{
    return parseFloat(rowData.Total_rounded_balance).toFixed(2)
  }
  const decimaltrd =(rowData)=>{
    return parseFloat(rowData.total_rounded_dividend).toFixed(2)
  }
  const decimalbsh =(rowData)=>{
    return parseFloat(rowData.beginingsharebalance).toFixed(2)
  }
  const decimalish =(rowData)=>{
    return parseFloat(rowData.inbetweesharebalance).toFixed(2)
  }
  const decimalesh =(rowData)=>{
    return parseFloat(rowData.endsharebalance).toFixed(2)
  }
  const decimalsshb =(rowData)=>{
    return parseFloat(rowData.selected_share_balance).toFixed(2)
  }
  const decimalsr =(rowData)=>{
    return parseFloat(rowData.share_rounded).toFixed(2)
  }
  const decimalsd =(rowData)=>{
    return parseFloat(rowData.share_dividend).toFixed(2)
  }
  const decimalbsu =(rowData)=>{
    return parseFloat(rowData.beginingsubsbalance).toFixed(2)
  }
  const decimalisu =(rowData)=>{
    return parseFloat(rowData.inbetweesubscritionbalance).toFixed(2)
  }
  const decimalesu =(rowData)=>{
    return parseFloat(rowData.endsubscriptionbalance).toFixed(2)
  }
  const decimalssub =(rowData)=>{
    return parseFloat(rowData.selected_subs_balance).toFixed(2)
  }
  const decimalsur =(rowData)=>{
    return parseFloat(rowData.subs_rounded).toFixed(2)
  }
  const decimalsud =(rowData)=>{
    return parseFloat(rowData.subs_dividend).toFixed(2)
  }


  const [dividendColumn, setDividendColumn] = useState([
    {field:"memberid", header:"M.No", visible:true, style:{width:"100px"}, filter:true},
    {field:"membername", header:"Member Name", visible:true, style:{width:"200px"}, filter: true},
    {field:"clearedDate", header:"Cleared Date", visible:true, style:{width:"100px"} },
    {field:"Total_rounded_balance", header:"Total Rounded Balance", visible:true, body:decimaltrb, style:{width:"200px"}},
    {field:"total_rounded_dividend", header:"Total Dividend Amount", visible:true, body:decimaltrd, style:{width:"200px"}},
    {field:"state", header:"State", visible:true, style:{width:"200px"}, filter: true},
    {field:"estate", header:"Estate", visible:true, style:{width:"200px"}, filter:true},
    {field:"beginingsharebalance", header:"Share Beginning Balance", body:decimalbsh, visible:true, style:{width:"200px"}},
    {field:"inbetweesharebalance", header:"Share Inbetween Balance", body:decimalish, visible:true, style:{width:"200px"}},
    {field:"endsharebalance", header:"Share End Balance", body:decimalesh, visible:true, style:{width:"200px"}},
    {field:"selected_share_balance", header:"Share Selected", body:decimalsshb, visible:true, style:{width:"200px"}},
    {field:"share_rounded", header:"Share Rounded", body:decimalsr, visible:true, style:{width:"200px"}},
    {field:"share_dividend", header:"Share Dividend", body:decimalsd, visible:true, style:{width:"200px"}},
    {field:"beginingsubsbalance", header:"Subscription Beginning Balance", body:decimalbsu, visible:true, style:{width:"200px"}},
    {field:"inbetweesubscritionbalance", header:"Subscription Inbetween Balance", body:decimalisu, visible:true, style:{width:"200px"}},
    {field:"endsubscriptionbalance", header:"Subscription End Balance", body:decimalesu, visible:true, style:{width:"200px"}},
    {field:"selected_subs_balance", header:"Subscription Selected", body:decimalssub, visible:true, style:{width:"200px"}},
    {field:"subs_rounded", header:"Subscription Rounded", body:decimalsur, visible:true, style:{width:"200px"}},
    {field:"subs_dividend", header:"Subscription Dividend", body:decimalsud, visible:true, style:{width:"200px"}},
  ])

  const [chequeClearedColumn, setChequeClearedColumn] = useState([
    {field:"date", header:"Date", visible:true, style:{width:"100px"}},
    {field:"amount", header:"Amount", visible:true, style:{width:"200px"}},
    {field:"accno", header:"A/C No", visible:true, style:{width:"200px"}},
    {field:"memberid", header:"MemberID", visible:true, style:{width:"200px"}},
    {field:"nric", header:"MyKAD", visible:true, style:{width:"200px"}},
    {field:"membername", header:"Member Name", visible:true, style:{width:"200px"}},
    {field:"status", header:"Status", visible:true, style:{width:"200px"}},
  ])

  const [dividendsaveColumn, setDividendsaveColumn] = useState([
    {field:"bank", header:"Bank", visible:true, style:{width:"100px"} },
    {field:"chequno", header:"Cheque No", visible:true, style:{width:"100px"} },
    {field:"clearedDate", header:"Cleared Date", visible:true, style:{width:"100px"} },
    {field:"memberid", header:"M.No", visible:true, style:{width:"100px"}, filter:true},
    {field:"membername", header:"Member Name", visible:true, style:{width:"200px"}, filter:true},
    {field:"Total_rounded_balance", header:"Total Rounded Balance", visible:true, body:decimaltrb, style:{width:"200px"}},
    {field:"total_rounded_dividend", header:"Cheque Amount", visible:true, body:decimaltrd, style:{width:"200px"}},
    {field:"dbs", header:"DBS Deduct", visible:true, style:{width:"200px"}},
    {field:"total_rounded_dividend", header:"Total Dividend Amount", visible:true, body:decimaltrd, style:{width:"200px"}},
    {field:"state", header:"State", visible:true, style:{width:"200px"}},
    {field:"transmasterid", header:"Bulk No", visible:true, style:{width:"200px"}},
    {field:"pv_no", header:"PV No", visible:true, style:{width:"200px"}}
  ])

  const header = (
    <div className="flex align-items-center gap-2" style={{display:"flex", marginLeft:"1300px", cursor:"pointer"}} onClick={(e)=>setDownloadExcel(true)}>
        <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:"600",lineHeight:"28px",color:"#242424"}}>Download Excel</Typography>
        <Button sx={{display:"flex"}} type="button" icon="pi pi-file-excel" severity="success" rounded data-pr-tooltip="XLS" ><DownloadIcon sx={{ color: "#64748B", fontSize: "24px" }} /></Button>
    </div>
);

  useEffect(()=>{
    if (firstchequeno!==""){
      console.log("here")
    let totalamt = 0
    const newdividend = dividendgen.map(item=>{
      totalamt += item.total_rounded_dividend
      return{
      ...item,
      chequno: "",
      bank:"",
      clearedDate:"",
      bulk_no:"",
      pv_no:"",
      dbs_deduct:0,
      withdrawal_bank:'',
      withdrawal_accno:'',
      withdrawal_amount:''
    }});
    setBulkAmt(totalamt)
    setDividendGen(newdividend)}

  },[firstchequeno])

  const dateformate = (date) =>{
    let formattedDate
        const CDate = new Date(date)
        const year = CDate.getFullYear();
        const month = String(CDate.getMonth() + 1).padStart(2, '0');
        const day = String(CDate.getDate()).padStart(2, '0');
        const hours = String(CDate.getHours()).padStart(2, '0');
        const minutes = String(CDate.getMinutes()).padStart(2, '0');
        const seconds = String(CDate.getSeconds()).padStart(2, '0');
        formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`
        console.log(formattedDate)
        return formattedDate
    }

  const handleFetchDividendDetails = async(e) =>{
    e.preventDefault();
    function getMiddleAndNextDate(date1, date2) {
      const date1Obj = new Date(date1);
      const date2Obj = new Date(date2); 
    
      // Calculate the middle timestamp
      const middleTimestamp = (date1Obj.getTime() + date2Obj.getTime()) / 2;
      const middleDate = new Date(middleTimestamp);
    
      // Calculate the next date by adding one day (24 hours) to the middle date
      const nextDate = new Date(middleDate.getTime() + 24 * 60 * 60 * 1000);
    
      // Format the dates back to the required format
      const middleDateFormatted = middleDate.toISOString().slice(0, 19) + "Z";
      const nextDateFormatted = nextDate.toISOString().slice(0, 19) + "Z";
    
      return { middleDate: middleDateFormatted, nextDate: nextDateFormatted };
    }
    
    //const { middleDate, nextDate } = getMiddleAndNextDate(formattedbegindate, formattedenddate);
    if (Dividend=="New Dividend"){
      let formattedbegindate = begindate?.format("YYYY-MM-DDTHH:mm:ss[Z]")
    let formattedenddate = endDate?.format("YYYY-MM-DDTHH:mm:ss[Z]")
    console.log(begindate, endDate, minShare, minSubs)
    const payload = {
        begin_date:dateformate(formattedbegindate),
        end_date:dateformate(formattedenddate),
        share_margin:parseInt(handleFormattedAmount(minShare)),
        subs_margin:parseInt(handleFormattedAmount(minSubs)),
        dividend:parseInt(dividenPercentage),
        year:""
    }
  //   const payload2 = {
  //     begin_date:dateformate(nextDate),
  //     end_date:dateformate(formattedenddate),
  //     share_margin:parseInt(minShare),
  //     subs_margin:parseInt(minSubs),
  //     dividend:parseInt(dividenPercentage),
  //     year:""
  // }
    if (transdate==null || begindate==null || endDate==null){
      setError("Please fill all the fields")
    }
    else{
      setLoading(true)
      setError("")
    await CustomAxios.post(`oasis/dividend/`,payload, {timeout:600000}).then((data) => {
      console.log(data.data)
      setDividendGen(data.data.data)
    setVisible(false)
    setLoading(false)})}}
    else{ 
     if (selectedOption==null || state==null || narration==null){
      console.log("here")
      setError("Please fill all the fields")
     }
     else{
      setError("")
      setLoading(true)
    await CustomAxios.get(`oasis/dividend/?narration=${narration}&cheque_status=${selectedOption}&state=${state}`).then((data) => {
      console.log(data.data)
      if (data.data.data.length==0){
        setError('could not find data for the given state')
        setLoading(false)
      }
      else{
      setDividendGen(data.data.data)
      setNarration(data.data.data[0]["narration"])
      setError('')
    setVisible(false)
    setSave(false)
    setPostDiv(false)
    setChequeCleared(true)
    setLoading(false)}}).catch(error=> console.error(error))
    }}
  }
  console.log(selectedOption, begindate, endDate, state, narration)
  console.log((selectedOption==null || begindate==null || endDate==null || state==null || narration==null))
  const handlewUploadExcelButtonClick = () => {
    // Trigger the file input click event
    fileInputRef.current.click();
  };

  const handlecheque=(e)=>{
    e.preventDefault()
    setChequeBoxDialog(true)
    
  }

  const handleAddCheque=()=>{
    const  increment = (no) =>{
      no = parseInt(no) + 1
      return no+""
    }
    if (firstchequeno==0){
      setError("chequeno should not be zero")
    }
    else if (bank==""||bank==null){
      setError("Please select a bankname")
    }
    else {
      setError('')
    let chequeno = parseInt(firstchequeno)-1
    const chequedividend = dividendgen.map(item=>{
      console.log(chequeno.length)
      chequeno = increment(chequeno);
      return {
      ...item,
      chequno: (chequeno.length==1)?"00000"+chequeno:(chequeno.length==2)?"0000"+chequeno:(chequeno.length==3)?"000"+chequeno:(chequeno.length==4)?"00"+chequeno:(chequeno.length==5)?"0"+chequeno:chequeno,
      bank:bank,}
    });
    //setSave(false)
    setDividendGen(chequedividend)
    setChequeBoxDialog(false)
    setPostDiv(true)}
  }

const handlePostDividend = async (e) => {
  e.preventDefault();
  if (coopbank == "" || coopbank == null){
    setError("Please select deposit bank")
  }
  else
  if (recordtype == "" || recordtype == null){
    setError("Plese select atlease one transaction")
  }
  else if (ccdate == "" || ccdate == null){
    setError("Plese select Cheque cleared date")
  }
  else{
  const payload = {
    begin_date:dateformate(begindate),
    end_date:dateformate(endDate),
    share_margin:parseInt(handleFormattedAmount(minShare)),
    subs_margin:parseInt(handleFormattedAmount(minSubs)),
    dividend:parseInt(dividenPercentage),
    transaction:recordtype,
    chequedate:dateformate(ccdate), 
    transdate:dateformate(transdate),
    totalamount:bulkamt,
    records:dividendgen,
    bankname:bank,
    coopbank:coopbank,
    createdby: localStorage.getItem('username'),
    narration:postnarration
  }
  setLoading(true)
  await CustomAxios.post(`oasis/post_dividend/`,payload).then((data) => {
    console.log(data.data)
    setDividendGen(data.data.data)
    setPostDiv(false)
    setPostDialog(false)
  setLoading(false)})}
}

const handlePostWithdrawal = async (e) => {
  e.preventDefault();
  const payload = {
    records:dividendgen,
    transdate:dateformate(transdate),
    narration:withdrawalnaration,
    createdby: localStorage.getItem('username')
  }
  setLoading(true)
  await CustomAxios.post(`oasis/dividend_withdrawal/`,payload).then((data) => {
    console.log(data.data)
  setDividendGen(data.data.data)
  setPostDialog(false)
  setClearedDialogBox(false)
  setLoading(false)})}

const numberFormat = (amount) => {
  const formattedAmount = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}).format(amount);
return formattedAmount
}

const handleCancelChqCleared = (e) => {
  setClearedDialogBox(false)
  
  setDividendGen(dividendgen.map(item => {
    if (chqClearedDateExcel.includes(item.memberid)) {
        return { ...item, clearedDate: '', withdrawal_bank:'', withdrawal_bank:'', withdrawal_amount:'' };
    }
    return item;
}))

}

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  const reader = new FileReader();
  setExcelFilename(file.name)
  
  reader.onload = (e) => {
    const binaryStr = e.target.result;
    const workbook = XLSX.read(binaryStr, { type: 'binary' });
    
    // Assuming the data is in the first sheet
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    
    // Convert sheet to JSON
    const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    
    // Extract data from a specific column by index, e.g., the first column (index 0)
    const columnIndex = 0; // Change this to the index of the column you want to extract
    let memberids = []
    const columnData = sheetData.map(row => {
      memberids.push(row[4])
      const isMemberFound = dividendgen.find(item1 =>  item1?.memberid===row[4]);
      
      let found='Not Found !'
      if(isMemberFound?.total_rounded_dividend - isMemberFound?.dbs === row[1]) {
        found="Found !"
      }

      const rawDate = row[columnIndex];
      let formattedDate;
        if (!isNaN(rawDate)) {
          // Convert Excel serial date to JavaScript Date
          const jsDate = new Date((rawDate - 25569) * 86400 * 1000);
          formattedDate = moment(jsDate).format('DD-MM-YYYY');
        } else {
          // Parse the date string and format it as DD-MM-YYYY
          formattedDate = moment(rawDate, ['DD/MMM/YYYY', 'DD/M/YYYY', 'DD/MM/YYYY']).format('DD-MM-YYYY');
        }
      if (row.length>0){
      return  {"date": formattedDate,"amount":row[1], "bank":row[2], "accno":row[3], "memberid":row[4], "nric":row[5], "membername":row[6] , "status":found}}});
    
      setChqClearedDateExcel(memberids)
    // Update the state with the extracted data
    const filtereddata = columnData.filter(obj=> obj && Object.keys(obj).length!==0)
    
    setClearedData(filtereddata);
    
  };

  reader.readAsBinaryString(file);
};

console.log(postnarration)

useEffect(()=>{
  setPostNarration(new Date().getFullYear() + " " + "Bonus " + recordtype + " " + dividenPercentage + "%")
},[recordtype, dividenPercentage])
  useEffect(()=>{
    const newdividend = dividendgen.map(item=>{
      const match = cleareddata.find(item1 =>  item1?.memberid===item?.memberid);
      return match && match.status === 'Found !' ? {
      ...item,
      clearedDate:match.date,
      withdrawal_bank:match.bank,
      withdrawal_accno:match.accno,
      withdrawal_amount:match.amount
    }:item});
    setDividendGen(newdividend)
    setCDateFound(true)
  },[cleareddata])

  console.log(cleareddata)
  let bankList = banks
    .filter((data) => data.bankname !== null)
    .map((filteredData) => filteredData.bankname);
    let coopbankList = coopbanks
    .filter((data) => data.coopbankname !== null)
    .map((filteredData) => filteredData.coopbankname);

  return (
    <Box className="fulldiv" style={{ width: "100%", height: "calc(100vh - 64px)", marginTop:"60px"}} >
    {downloadexcel && (
          <Dialog
            visible={downloadexcel}
            style={{
              width: "450px",
              boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)",
              background: "#f2f6fc",
              borderRadius: "0px",
            }}
            className="custombar1"
            draggable={false}
            position="center"
            modal={false}
            closable={false}
            resizable={false}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "16px",
              }}
            >
              <Typography
                sx={{
                  alignSelf: "stretch",
                  width: "100%",
                  fontFamily: "inter",
                  fontSize: "16px",
                  fontStyle: "inter",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#288d2c",
                }}
              >
                Please choose an option and click Download Excel
              </Typography>
              <div
                className="flex align-items-center"
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <RadioButton
                  inputId="Option1"
                  value="detailed"
                  style={{ display: "flex", alignItems: "center" }}
                  onChange={(e) => setExcelType("gendiv")}
                  checked={exceltype === "gendiv"}
                />
                <label htmlFor="Option1" className="ml-2">
                  Generated Dividend List
                </label>
              </div>
              <div
                className="flex align-items-center"
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <RadioButton
                  inputId="Option1"
                  value="detailed"
                  style={{ display: "flex", alignItems: "center" }}
                  onChange={(e) => setExcelType("currdiv")}
                  checked={exceltype === "currdiv"}
                />
                <label htmlFor="Option1" className="ml-2">
                  Post Dividend List
                </label>
              </div>
              <div
                className="flex align-items-center"
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <RadioButton
                  inputId="Option1"
                  value="detailed"
                  style={{ display: "flex", alignItems: "center" }}
                  onChange={(e) => setExcelType("pbecp")}
                  checked={exceltype === "pbecp"}
                />
                <label htmlFor="Option1" className="ml-2">
                  PBecp Template
                </label>
              </div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  onClick={(e)=>exportExcel()}
                  sx={{
                    width: "200px",
                    display: "flex",
                    flexShrink: 0,
                    fontSize: "16px",
                    padding: "12px",
                    height: "36px",
                  }} // Adjust width as needed
                >
                  Download Excel
                </Button>
                <Button
                  variant="contained"
                  onClick={(e) => setDownloadExcel(false)}
                  sx={{
                    width: "150px",
                    display: "flex",
                    flexShrink: 0,
                    fontSize: "16px",
                    padding: "12px",
                    height: "36px",
                  }} // Adjust width as needed
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Dialog>
        )}
      {visible==false ? <Box>
                <Box sx={{ display:"flex",flexDirection:"column",gap:"20px",height: {sm:'initial',padding: "0px 20px 0px 20px"},width:"100%" }}>
                {chequeboxdialog ? <Dialog
                  visible={chequeboxdialog}
                  style={{
                    width: "450px",
                    boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)",
                    background: "#f2f6fc",
                    borderRadius: "0px",
                  }}
                  className="custombar1"
                  draggable={false}
                  position="center"
                  modal={false}
                  closable={false}
                  resizable={false}
                >
                  <Box sx={{display:"flex", flexDirection:"column", padding:"32px", gap:"20px"}}>
                  <Typography sx={{fontFamily:"inter",fontSize:"20px",fontWeight:"600",lineHeight:"28px",color:"#288d2c"}}>Cheque Number Insertion</Typography>
                  <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:"600",lineHeight:"28px",color:"#101010"}}>Please select Bank name and cheque no</Typography>
                  <MuiAutocomplete
                                  labelId="select-bank-label"
                                  id="select-bank"
                                  value={bank}
                                  onChange={(event, newValue) =>
                                    setBank(newValue)
                                  }
                                  options={bankList.sort()}
                                  label="Select Bank"
                                  autoCompleteStyle={{
                                    width: "280px",
                                    backgroundColor: "#FFF",
                                    fontSize: "8px",
                                    fontWeight: "400",
                                    "& .MuiInputBase-input": {
                                      color: "#000",
                                      marginLeft: "10px",
                                    },
                                    color:"#101010",
                                  }}
                                />
                          <MuiInput
                              label="First Cheque No"
                              variant="outlined"
                              type="number"
                              value={firstchequeno}
                              onChange={(e) => setFirstChequeNo(e.target.value)}
                              sx={{
                                width: "280px",
                                backgroundColor: "#FFF",
                                fontSize: "14px",
                                color: "#101010",
                              }}
                              InputProps={{
                                maxLength: 6,
                                style: {
                                  whiteSpace: "nowrap",
                                },
                              }}
                        />
                        {error!="" && <Typography sx={{fontFamily:"inter",fontSize:"14px",fontWeight:"600",lineHeight:"28px",color:"red"}}>{error}</Typography>}
                        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                          <Button sx={{width:"150px"}} variant="contained" color="primary" onClick={(e)=>handleAddCheque(e)}><Typography sx={{width:"100%",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px",textTransform:"none",textAlign:"center"}}>Ok</Typography></Button>
                          <Button sx={{width:"150px"}} variant="contained" color="primary" onClick={(e)=>{setChequeBoxDialog(false) 
                          setError('')}}><Typography sx={{width:"100%",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px",textTransform:"none",textAlign:"center"}}>Cancel</Typography></Button>
                        </Box>
                    
                  </Box>
                </Dialog>:postdialag && <Dialog
                visible={postdialag}
                  style={{
                    width: "750px",
                    boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)",
                    background: "#f2f6fc",
                    borderRadius: "0px",
                  }}
                  className="custombar1"
                  draggable={false}
                  position="center"
                  modal={false}
                  closable={false}
                  resizable={false}>
                  <Box sx={{display:"flex", flexDirection:"column", padding:"32px", gap:"20px"}}>
                <Typography sx={{fontFamily:"inter",fontSize:"20px",fontWeight:"600",lineHeight:"28px",color:"#288d2c"}}>Dividend Posting</Typography>
                <Box sx={{display:"flex", flexDirection:"row", gap:"16px"}}>
                <MuiAutocomplete
                                  labelId="select-bank-label"
                                  id="select-bank"
                                  value={coopbank}
                                  onChange={(event, newValue) =>
                                    setCoopBank(newValue)
                                  }
                                  options={coopbankList.sort()}
                                  label="Select Bank (Deposit)"
                                  autoCompleteStyle={{
                                    width: "280px",
                                    backgroundColor: "#FFF",
                                    fontSize: "8px",
                                    fontWeight: "400",
                                    "& .MuiInputBase-input": {
                                      color: "#000",
                                      marginLeft: "10px",
                                    },
                                    color:"#101010",
                                  }}
                                />
                                <Typography sx={{fontFamily:"inter",fontSize:"14px",fontWeight:"600",lineHeight:"28px",color:"#101010"}}>Koperasi Serbaguna's Bank A/C</Typography>
                                </Box>
                                <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                <MuiDatePicker
                                      label="Cheque Cleared Date"
                                      value={ccdate}
                                      onChange={(e) => setCcDate(e)}
                                      disableFuture
                                      // minDate={new Date('2000-01-01')}
                                      format="DD-MM-YYYY"
                                      sx={{ width:"280px", backgroundColor: "white" }}
                                    />
                                <MuiInput
                                      label=""
                                      disabled
                                      value="Dividend Generated 2023"
                                      InputProps={{readOnly:true}}
                                      sx={{width:"330px", backgroundColor: "white" }}
                                    />
                                </Box>
                                <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                <MuiInput
                                  label="Bulk No"
                                  variant="outlined"
                                  disabled
                                  value={bulkno}
                                  InputProps={{readOnly:true}}
                                  sx={{
                                    width: "280px",
                                    backgroundColor: "#FFF",
                                    fontSize: "14px",
                                    color: "#101010",
                                  }}
                                />
                                <MuiInput
                                  label="Bulk Amount"
                                  disabled
                                  variant="outlined"
                                  value={numberFormat(bulkamt.toFixed(2))}
                                  InputProps={{readOnly:true}}
                                  sx={{
                                    width: "280px",
                                    backgroundColor: "#FFF",
                                    fontSize: "14px",
                                    color: "#101010",
                                  }}
                                />
                                </Box>
                                <Box sx={{display:"flex", flexDirection:"row", gap:"50px"}}>
                                <Typography sx={{fontFamily:"inter",fontSize:"14px",fontWeight:"600",lineHeight:"28px",color:"#101010"}}>Member's A/C To Post To:</Typography>
                                <div
                                  className="flex align-items-center"
                                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                                >
                                  <RadioButton
                                    inputId="Option1"
                                    value="detailed"
                                    style={{ display: "flex", alignItems: "center" }}
                                    onChange={(e) => setRecordType("share")}
                                    checked={recordtype === "share"}
                                  />
                                  <label htmlFor="Option1" className="ml-2">
                                    Share
                                  </label>
                                </div>
                                <div
                                  className="flex align-items-center"
                                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                                >
                                  <RadioButton
                                    inputId="Option1"
                                    value="detailed"
                                    style={{ display: "flex", alignItems: "center" }}
                                    onChange={(e) => setRecordType("subscription")}
                                    checked={recordtype === "subscription"}
                                  />
                                  <label htmlFor="Option1" className="ml-2">
                                    Subscription
                                  </label>
                                </div>


                                </Box>
                                <MuiInput
                                  label="Narration"
                                  value={postnarration}
                                  onChange={(e)=>setPostNarration(e.target.value)}
                                  sx={{
                                    width: "280px",
                                    backgroundColor: "#FFF",
                                    fontSize: "14px",
                                    color: "#101010",
                                  }}
                                />
                                <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                  <Button sx={{width:"150px" }} variant="contained" color="primary" onClick={(e)=>handlePostDividend(e)}>{loading? <CircularProgress color="inherit" size={30}/>: <Typography sx={{width:"100%",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px",textTransform:"none",textAlign:"center"}}>Ok</Typography>}</Button>
                                  <Button sx={{width:"150px"}} variant="contained" color="primary" onClick={(e)=>{setPostDialog(false)
                                  setError('') }}><Typography sx={{width:"100%",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px",textTransform:"none",textAlign:"center"}}>Cancel</Typography></Button>
                                </Box>
                                {error!="" && <Typography sx={{fontFamily:"inter",fontSize:"14px",fontWeight:"600",lineHeight:"28px",color:"red"}}>{error}</Typography>}
                                </Box>

                </Dialog>}

                {cleareddialogbox && <Dialog
                visible={cleareddialogbox}
                  style={{
                    width: "1000px",
                    boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)",
                    background: "#f2f6fc",
                    borderRadius: "0px",
                  }}
                  className="custombar1"
                  draggable={false}
                  position="center"
                  modal={false}
                  closable={false}
                  resizable={false}>
                  <Box sx={{display:"flex", flexDirection:"column", padding:"32px", gap:"20px"}}>
                  <Typography sx={{fontFamily:"inter",fontSize:"20px",fontWeight:"600",lineHeight:"28px",color:"#288d2c"}}>Group Dividend Selection System</Typography>
                  <Box sx={{width:"100%"}}>
                  <DataTable value={cleareddata} scrollable style={{ backgroundColor: "#F1F5F9" }} rows={7} dataKey="sn" paginator rowcolumnfinalMembersizeMode="expand" removableSort finalMembersizableColumns filterDisplay="row" emptyMessage="No Record Found.">
                  <Column header="SN" headerStyle={{ width: '50px' }} body={(data, options) => options.rowIndex + 1}></Column>
                  {chequeClearedColumn.filter(col => col.visible).map((col,ind) => <Column className="custom-column-style" style={col.style} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                  </DataTable>
                  </Box>
                  <Box sx={{display:"flex", flexDirection:"row", gap:"20px"}}>
                  <Box sx={{display:"flex", flexDirection:"column", gap:"20px"}}>
                  <Box sx={{display:"flex", flexDirection:"row", gap:"16px"}}>
                  <Box sx={{display:"flex", flexDirection:"column", gap:"20px"}}>
                      <Typography sx={{fontFamily:"inter",fontSize:"14px",fontWeight:"600",lineHeight:"16px",color:"#101010"}}>Withdrawal Narration</Typography>
                    </Box>
                  <MuiInput
                                  label="Narration"
                                  variant="outlined"
                                  value={withdrawalnaration}
                                  onChange={(e)=>setWithdrawalNarration(e.target.value)}
                                  sx={{
                                    width: "280px",
                                    backgroundColor: "#FFF",
                                    fontSize: "14px",
                                    color: "#101010",
                                  }}
                                />
                    </Box>
                    <Box sx={{display:"flex", flexDirection:"row", gap:"16px"}}>
                      <Box sx={{display:"flex", flexDirection:"column", gap:"20px"}}>
                      <Typography sx={{fontFamily:"inter",fontSize:"14px",fontWeight:"600",lineHeight:"16px",color:"#101010"}}>Get Data from MS Excel</Typography>
                      {cleareddata.length>0 && <Typography sx={{fontFamily:"inter",fontSize:"12px",fontWeight:"600",lineHeight:"28px",color:"#101010"}}>Filename : {excelfilename}</Typography>}
                    </Box>
                    <input 
                        type="file" 
                        accept=".xlsx, .xls" 
                        ref={fileInputRef} 
                        onChange={handleFileUpload} 
                        style={{ display: 'none' }} // Hide the file input
                    />
                    <Button sx={{width:"250px" }} variant="contained" color="primary" onClick={handlewUploadExcelButtonClick}><Typography sx={{width:"100%",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px",textTransform:"none",textAlign:"center"}}>Upload Excel File</Typography></Button>
                    <Button sx={{width:"150px"}} variant="contained" color="primary" onClick={(e)=>setClearedDialogBox(false)}><Typography sx={{width:"100%",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px",textTransform:"none",textAlign:"center"}}>Ok</Typography></Button>
                    <Button sx={{width:"150px"}} variant="contained" color="primary" onClick={handleCancelChqCleared}><Typography sx={{width:"100%",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px",textTransform:"none",textAlign:"center"}}>Cancel</Typography></Button>
                    </Box>
                  </Box>
                  </Box>
                  </Box>
                  
                </Dialog>}
                     <Box sx={{width:"100%",top:"72px",left:"304px",padding:"8px 0px 8px 0px",display:"flex",justifyContent:"space-between",borderBottom:"1px solid #CBD5E1",alignItems:"center",height:"84px"}}>
                         <Box sx={{maxWidth:"200px",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                            <Box sx={{width:"32px",height:"32px"}}>
                                    <StoreOutlinedIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                             </Box>
                     <Typography sx={{fontFamily:"inter",fontSize:"20px",fontWeight:"600",lineHeight:"28px",color:"#242424"}}>Dividend</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "20px", width:"700px" }}>
                {/* <Box className="p-input-icon-left" sx={{display:"flex",gap:"24px"}}>
                            <i className="pi pi-search" style={{color:"#64748B",zIndex:"2"}}/>
                            <InputText value={estateid} placeholder="Estate ID" onKeyUp={(e)=>handleEnter(e)} onChange={(e) => setEstatid(e.target.value)} style={{height:"48px",width:"400px",fontSize:"16px",color:"#64748B"}}/>
                </Box> */}
                {save && <Button onClick={(e)=>{
                    handlecheque(e)}} variant="contained"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"#288d2c", textTransform: "none"}}><PaymentsOutlinedIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/> <Typography sx={{color:"#FFFFFF",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Enter Cheque No</Typography></Button>}
                {chequecleared && <Button onClick={(e)=>{
                    handlePostWithdrawal(e)}} variant="contained"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"#288d2c", textTransform: "none"}}><PaymentsOutlinedIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/>{loading? <CircularProgress color="inherit" size={30}/>: <Typography sx={{width:"100%",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px",textTransform:"none",textAlign:"center"}}>Post Withdrawal</Typography>}</Button>}
                {dividendgen?.length==0 && Dividend=="New Dividend"?<Button onClick={(e)=>{
                    setVisible(true)}} variant="contained"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"#288d2c", textTransform: "none"}}><StoreOutlinedIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/> <Typography sx={{color:"#FFFFFF",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Generate Dividend</Typography></Button>:
                    postdiv ? 
                    <Button onClick={(e)=>{
                    setPostDialog(true)}} variant="contained"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"#288d2c", textTransform: "none"}}><StoreOutlinedIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/> <Typography sx={{color:"#FFFFFF",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Post Dividend</Typography></Button>:
                    chequecleared ? 
                    <Button onClick={(e)=>{
                    setClearedData([])
                    setClearedDialogBox(true)}} variant="contained"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"#288d2c", textTransform: "none"}}><StoreOutlinedIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/> <Typography sx={{color:"#FFFFFF",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Withdrawal</Typography></Button>:
                    savebutton ? <Button onClick={(e)=>{
                    setSaveButton(false)
                    setSave(true)}} variant="contained"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"#288d2c", textTransform: "none"}}><StoreOutlinedIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/> <Typography sx={{color:"#FFFFFF",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Save Dividend</Typography></Button>:<></>
                    }

                    <Button onClick={(e)=>{
                    clearform(e)}} variant="contained"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"#288d2c", textTransform: "none"}}><ClearIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/> <Typography sx={{color:"#FFFFFF",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Clear</Typography></Button>
            </Box>
            </Box>
          </Box>
          {dividendgen?.length>0 && <Box sx={{width:"100%"}}>
          <DataTable value={dividendgen} visible={dividendgen?.length>0 && true} header = {header} scrollable style={{ backgroundColor: "#F1F5F9" }} rows={7} dataKey="sn" paginator rowcolumnfinalMembersizeMode="expand" removableSort finalMembersizableColumns filters={filters} filterDisplay="row" emptyMessage="No Dividends Generated.">
          <Column header="SN" headerStyle={{ width: '50px' }} body={(data, options) => options.rowIndex + 1}></Column>
          {(save||chequecleared) ? dividendsaveColumn.filter(col => col.visible).map((col,ind) => <Column className="custom-column-style" style={col.style} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />):dividendColumn.filter(col => col.visible).map((col,ind) => <Column className="custom-column-style" style={col.style} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
          </DataTable>
          </Box>}
        </Box>
        

    :<Box
      sx={{
        backgroundColor: isMobile && "#f2f6fc",
        minHeight: isMobile && "100vh",
        width: "100%",
      }}
    >
      <Box sx={{ padding: "20px" }}>
        <Dialog
          visible={visible}
          style={{ width: "1000px", height:Dividend=="New Dividend" ?"500px":"300px", position: "relative" }}
          onHide={(e) => clearform(e)}
        >
            <form onSubmit={(e)=>handleFetchDividendDetails(e)}>
          <Box sx={{ padding: "0px 20px 20px 20px" }}>
            <Typography sx={{ color: "black", fontWeight: "600" }}>
              Criteria to Process / View Existing Dividend
            </Typography>
            <Box>
              <Box sx={{ marginTop:"20px", display: "flex", gap: "20px", alignItems: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <RadioButton
                    style={{ display: "flex", alignItems: "center" }}
                    inputId="type1"
                    name="dividend"
                    value="New Dividend"
                    onChange={(e) => handleDividendChange(e, "New Dividend")}
                    checked={Dividend=="New Dividend"}
                  />
                  <label htmlFor="type1" className="ml-2">
                    New Dividend
                  </label>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <RadioButton
                      style={{ display: "flex", alignItems: "center" }}
                      inputId="type2"
                      name="dividend"
                      value="View Existing"
                      onChange={(e) => handleDividendChange(e, "View Existing")}
                      checked={Dividend === "View Existing"}
                    />
                    <label htmlFor="type2" className="ml-2">
                      View Existing
                    </label>
                    <Box>
                      {/* <Select
                        displayEmpty
                        style={{
                          width: "150px",
                          height: "36px",
                          fontFamily: "inter",
                          fontSize: "16px",
                        }}
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        disabled={Dividend !== "View Existing" ? true : false}
                      >
                        <MenuItem value="" disabled>
                          Select Option
                        </MenuItem>
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="Not Cleared">Not Cleared</MenuItem>
                        <MenuItem value="Cleared">Cleared</MenuItem>
                        <MenuItem value="Cheque Canceled">
                          Cheque Canceled
                        </MenuItem>
                      </Select> */}
                      {Dividend=="View Existing" && <MuiAutocomplete
                                labelId="select-state-label"
                                id="select-state"
                                required
                                value={selectedOption}
                                onChange={(event, newValue) => {
                                  setSelectedOption(newValue);
                                }}
                                options={allchequestatus.sort()}
                                label="Cheque Status"
                                autoCompleteStyle={{ width: "230px", marginTop: "0px" }}
                                textFieldStyle={{
                                  width: "230px",
                                  fontSize: "8px",
                                  fontWeight: "400",
                                  "& .MuiInputBase-input": {
                                    color: "#000",
                                    marginLeft: "10px",
                                  },
                                  color: "#101010",
                                }}
                              />}
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <MuiDatePicker
                      label="Trans Date"
                      required
                      value={transdate}
                      onChange={(e) => setTransDate(e)}
                      disableFuture
                      // minDate={new Date('2000-01-01')}
                      format="DD-MM-YYYY"
                      sx={{ backgroundColor: "white", width:"230px" }}
                    />
                </Box>
              </Box>
              {/* <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography
                  disabled={Dividend !== "View Existing" ? true : false}
                >
                  Existing Year
                </Typography>
                <Select
                  displayEmpty
                  style={{
                    width: "150px",
                    height: "36px",
                    fontFamily: "inter",
                    fontSize: "16px",
                  }}
                  disabled = {true}
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  // disabled={Dividend !== "View Existing" ? true : false}
                >
                  <MenuItem value="" disabled>
                    Select Year
                  </MenuItem>
                  {yearOptons.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </Box> */}
            </Box>

            <Box sx={{ display: "flex", gap: "20px",flexDirection:"column", marginTop:"20px" }}>
              {Dividend=="New Dividend" && <Box sx={{ display: "flex", alignItems: "center" ,flexDirection:"row", gap:"20px"}}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* <MuiInput
                    type="number"
                    label="Min Share balance"
                    required
                    variant="outlined"
                    value={minShare}
                    onChange={(e) => setMinshare(e.target.value)}
                    sx={{
                      width: "100%",
                      backgroundColor: "#FFF",
                      fontSize: "14px",
                      color:"#101010",
                    }}
                  /> */}
                  <MuiInputAmount
                      inputKey="amount1_dividend"
                      ref={minShBalanceRef}
                      amount={minShare}
                      setAmount={setMinshare}
                      label="Min Share balance"
                      required
                      sx={{
                        width: "100%",
                        backgroundColor: "#FFF",
                        fontSize: "14px",
                        color:"#101010",
                      }}
                  />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <MuiInput
                    type="number"
                    label="Min Subs Balance"
                    variant="outlined"
                    required
                    value={minSubs}
                    onChange={(e) => setMinSubs(e.target.value)}
                    sx={{
                      width: "100%",
                      backgroundColor: "#FFF",
                      fontSize: "14px",
                      color:"#101010",
                    }}
                  /> */}
                  <MuiInputAmount
                    inputKey="amount2_dividend"
                    ref={minSubsBalanceRef}
                    amount={minSubs}
                    setAmount={setMinSubs}
                    label="Min Subs Balance"
                    required
                    sx={{
                      width: "100%",
                      backgroundColor: "#FFF",
                      fontSize: "14px",
                      color:"#101010",
                    }}
                  />
              </Box>
              </Box>}
              <Box sx={{ display: "flex", gap: "20px" ,flexDirection:"column"}}>
                {Dividend=="New Dividend" && <><Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* <Typography
                    sx={{
                      alignSelf: "stretch",
                      width: "200px",
                      fontFamily: "inter",
                      fontSize: "16px",
                      fontStyle: "inter",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#242424",
                    }}
                  >
                    Begin - Date
                  </Typography> */}
                  <MuiDatePicker
                      label="Begin Date"
                      required
                      value={begindate}
                      onChange={(e) => setBeginDate(e)}
                      disableFuture
                      // minDate={new Date('2000-01-01')}
                      format="DD-MM-YYYY"
                      sx={{ backgroundColor: "white" }}
                    />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* <Typography
                    sx={{
                      alignSelf: "stretch",
                      width: "200px",
                      fontFamily: "inter",
                      fontSize: "16px",
                      fontStyle: "inter",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#242424",
                    }}
                  >
                    End - Date
                  </Typography> */}
                  <MuiDatePicker
                      label="End Date"
                      required
                      value={endDate}
                      onChange={(e) => setEndDate(e)}
                      disableFuture
                      // minDate={new Date('2000-01-01')}
                      format="DD-MM-YYYY"
                      sx={{ backgroundColor: "white" }}
                    />
                </Box></>}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* <Typography
                  sx={{
                    alignSelf: "stretch",
                    width: "200px",
                    fontFamily: "inter",
                    fontSize: "16px",
                    fontStyle: "inter",
                    fontWeight: "400",
                    lineHeight: "24px",
                    color: "#242424",
                  }}
                >
                  Enter dividend (%)
                </Typography> */}
                {Dividend=="View Existing" &&<Box sx={{display:"flex", flexDirection:"row", gap:"20px"}}> <MuiAutocomplete
                                labelId="select-state-label"
                                id="select-state"
                                value={state}
                                required
                                onChange={(event, newValue) => {
                                  setState(newValue);
                                }}
                                options={states.sort()}
                                label="State"
                                autoCompleteStyle={{ width: "230px", marginTop: "0px" }}
                                textFieldStyle={{
                                  width: "230px",
                                  fontSize: "8px",
                                  fontWeight: "400",
                                  "& .MuiInputBase-input": {
                                    color: "#000",
                                    marginLeft: "10px",
                                  },
                                  color: "#101010",
                                }}
                              />
                                <MuiAutocomplete
                                labelId="select-state-label"
                                id="select-state"
                                value={narration}
                                required
                                onChange={(event, newValue) => {
                                  setNarration(newValue);
                                }}
                                options={divnarrations.sort()}
                                label="Narration"
                                autoCompleteStyle={{ width: "230px", marginTop: "0px" }}
                                textFieldStyle={{
                                  width: "330px",
                                  fontSize: "8px",
                                  fontWeight: "400",
                                  "& .MuiInputBase-input": {
                                    color: "#000",
                                    marginLeft: "10px",
                                  },
                                  color: "#101010",
                                }}
                              />
                              </Box>}
              </Box>
                {/* <Typography
                  sx={{
                    alignSelf: "stretch",
                    width: "200px",
                    fontFamily: "inter",
                    fontSize: "16px",
                    fontStyle: "inter",
                    fontWeight: "400",
                    lineHeight: "24px",
                    color: "#242424",
                  }}
                >
                  Narration
                </Typography> */}
                {Dividend=="New Dividend" && <Box sx={{display:"flex", flexDirection:"row", gap:"20px"}}><MuiInput
                    type="number"
                    label="Dividend"
                    variant="outlined"
                    required
                    value={dividenPercentage}
                    onChange={(e) => setDividendPercentage(e.target.value)}
                    sx={{
                      width: "330px",
                      backgroundColor: "#FFF",
                      fontSize: "14px",
                      color:"#101010",
                    }}
                  />
                  <MuiInput
                    label="Narration"
                    variant="outlined"
                    required
                    disabled
                    value={"Dividend Generated"}
                    onChange={(e) => setNarration(e.target.value)}
                    sx={{
                      width: "330px",
                      backgroundColor: "#FFF",
                      fontSize: "14px",
                      color:"#101010",
                    }}
                  /></Box>}
              </Box>
            </Box>
          </Box>
          <Box>
          {error!="" && <Typography sx={{marginLeft:"20px",fontFamily:"inter",fontSize:"14px",fontWeight:"600",lineHeight:"28px",color:"red"}}>{error}</Typography>}
            {Dividend=="New Dividend" && <Button type="submit" fullWidth variant="contained" color="primary" disabled={dividendgen.length>0} sx={{marginLeft:"20px", width:"165px",height:"48px",borderRadius:"4px",display:"flex"}}>{loading? <CircularProgress color="inherit" size={30}/> : <Typography sx={{width:"100%",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px",textTransform:"none",textAlign:"center"}}>New Process</Typography>}</Button>}
            {Dividend=="View Existing" && <Button type="submit" fullWidth variant="contained" color="primary" disabled={dividendgen.length>0} sx={{marginLeft:"20px", width:"165px",height:"48px",borderRadius:"4px",display:"flex"}}>{loading? <CircularProgress color="inherit" size={30}/> : <Typography sx={{width:"100%",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px",textTransform:"none",textAlign:"center"}}>View Existing</Typography>}</Button>}
          </Box>
          </form>
        
        </Dialog>
      </Box>
    </Box>}

    </Box>
    
  );
}