import { Box, Typography, Button } from "@mui/material"
import { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import ClearAllIcon from '@mui/icons-material/ClearAll';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DownloadIcon from '@mui/icons-material/Download';
import TableChartIcon from '@mui/icons-material/TableChart';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { InputText } from "primereact/inputtext";
import { Form } from "./form";
import { Dialog } from 'primereact/dialog';
import SettingsIcon from '@mui/icons-material/Settings';
import { useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { updateModalOpen, } from "../redux/executiveReducer";
import { useDispatch } from "react-redux";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { CSVLink } from 'react-csv'
import { Checkbox } from "primereact/checkbox";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import * as XLSX from 'xlsx';


const ReceiptIssued = () => {
    const receiptIssued = useSelector(state => state.executive.receiptIssuedData)
    console.log(receiptIssued)
    const receiptIssuedType = useSelector(state => state.executive.receiptIssuedType)
    const [visible, setVisible] = useState(false);
    const [filterState, setFilterState] = useState(false)
    const [loading, setLoading] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [totalRecords, setTotalRecords] = useState(null)
    const [globalFilterFields, setGlobalFilterFields] = useState([])
    const modalOpen = useSelector(state => state.executive.modalOpen)
    const [arrangeTable, setArrangeTable] = useState(false)
    const dispatch = useDispatch()
    const [exportCSV, setExportCSV] = useState([])
    const [seperatedColumn, setSeperatedColumn] = useState([])
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [selectedColumn, setSelectedColumn] = useState([])
    const [formattedData, setFormattedData] = useState([])
    const dt = useRef(null);
    const formPayload = useSelector(state => state.executive.formPayload)
    const isSidebarOpen = useSelector((state) => state.executive.open);

    const [filters, setFilters] = useState({});
    useEffect(() => {
        if (receiptIssued.length > 0) {
            let exportHeaders = [...exportCSV]
            exportHeaders = columnVisible.filter((item) => item.visible).map(item => { return { label: item.header, key: item.field } })

            setExportCSV(exportHeaders)
            formattingData()


        }


    }, [formPayload])

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const CustomFilter = (options) => {

        return (
            <InputText style={{ width: "182px", height: "36px" }} placeholder="search" value={options.value} onChange={(e) => options.filterApplyCallback(e.target.value,)} />
        );
    };


    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => { options.filterApplyCallback(e.target.value); }} dateFormat="dd/mm/yyyy" placeholder="dd/mm/yyyy" />;
    };

    const dateOfJoinBody = (rowData) => {
        const originalDate = new Date(rowData.member.doj);
        const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = originalDate.getDate().toString().padStart(2, '0');
        const year = originalDate.getFullYear();

        const formattedDate = `${day}-${month}-${year}`

        return formattedDate;
    }
    const onColumnChange = (e) => {
        let column = [...columnVisible]
        let _selectedCategories = [...seperatedColumn];
        if (e.checked) {
            //console.log(e.value)
            let deselect = column.filter(item => item.header == e.value.header).map(item => { item.visible = true; return item })
            //console.log(column)
            deselect = [...column, ...deselect];
            deselect = [...new Set(deselect)]
            //console.log(deselect)
            setSelectedColumn(deselect)
            _selectedCategories.push(e.value);
        }
        else {
            //console.log(e.value)
            let deselect = column.filter(item => item.header == e.value.header).map(item => { item.visible = false; return item })
            //console.log(column)
            deselect = [...column, ...deselect];
            deselect = [...new Set(deselect)]
            //console.log(deselect)
            setSelectedColumn(deselect)
            _selectedCategories = _selectedCategories.filter(category => category.header !== e.value.header);


        }
        setSeperatedColumn(_selectedCategories)

    };
    const applyFilter = () => {
        let columns = [...columnVisible]


        // columns=columns.map(column=>{
        //     selectedColumn.some(item=>)
        //     // console.log(array)
        //     return column

        // })
        //console.log(columns)
        //console.log(selectedColumn)

        setColumneVisible(selectedColumn)
        setArrangeTable(false)
        let exportHeaders = [...exportCSV]
        //console.log(exportHeaders)
        exportHeaders = selectedColumn.filter((item) => item.visible).map(item => { return { label: item.header, key: item.field } })
        //console.log(exportHeaders)

        setExportCSV(exportHeaders)
    }
    const shareTemplate = (rowData) => {
        // console.log(rowData)
        if (rowData.transtypeid == 1) {
            return rowData.transamount
        }
        else {
            return 0.00
        }
    }
    const subsTemplate = (rowData) => {
        if ((rowData.transtypeid == 2) || (rowData.transtypeid == 12)) {
            return rowData.transamount
        }
        else {
            return 0.00
        }

    }
    const loanTemplate = (rowData) => {
        if (rowData.transtypeid == 5) {
            return rowData.transamount
        }
        else {
            return 0.00
        }
    }
    const sDTemplate = (rowData) => {
        if (rowData.transtypeid == 6) {
            return rowData.transamount
        }
        else {
            return 0.00
        }
    }
    const BRTTemplate = (rowData) => {
        if (rowData.transtypeid == 3) {
            return rowData.transamount
        }
        else {
            return 0.00
        }
    }
    const entTemplate = (rowData) => {
        if (rowData.transtypeid == 7) {
            return rowData.transamount
        }
        else {
            return 0.00
        }
    }
    const summeryShareTemplate = (rowData) => {
        let Total = 0
        if (rowData.transtypeid == 1) {
            Total += parseInt(rowData.transamount)
        }
        else {
            Total += 0.00
        }
        return Total
    }
    const summerySubsTemplate = (rowData) => {
        let total = 0
        if (rowData.transtypeid == 2) {
            total += parseInt(rowData.transamount)
        }
        else {
            total += 0.00
        }
        return total
    }
    const summeryLoanTemplate = (rowData) => {
        let total = 0
        if (rowData.transtypeid == 5) {
            total += parseInt(rowData.transamount)
        }
        else {
            total += 0.00
        }
        return total

    }
    const summeryEFTemplate = (rowData) => {
        let total = 0
        if (rowData.transtypeid == 7) {
            total += parseInt(rowData.transamount)
        }
        else {
            total += 0.00
        }
        return total
    }
    const summerySDTemplate = (rowData) => {
        let total = 0
        if (rowData.transtypeid == 6) {
            total += parseInt(rowData.transamount)
        }
        else {
            total += 0.00
        }
        return total

    }
    const summeryBRTemplate = (rowData) => {
        let total = 0
        if (rowData.transtypeid == 3) {
            total += parseInt(rowData.transamount)
        }
        else {
            total += 0.00
        }
        return total

    }
    const SdInAmount = (rowData) => {
        if (rowData.transtypeid == 6) {
            return rowData.transamount
        }
        else {
            return 0.00
        }
    }
    const SdOutAmount = (rowData) => {
        if (rowData.transtypeid == 24) {
            return rowData.transamount
        }
        else {
            return 0.00
        }

    }
    const summeryReceiptDate = (rowData) => {
        const item = rowData.transdate.split("T")[0]
        const originalDate = new Date(item);
        const year = originalDate.getFullYear();
        const month = String(originalDate.getMonth() + 1).padStart(2, '0');
        const day = String(originalDate.getDate()).padStart(2, '0');

        let formattedDate = `${day}-${month}-${year}`;

        return formattedDate

    }
    const [columnVisible, setColumneVisible] = useState([])
    const decimalSDin = (rowData) => {

        if (isNaN(rowData.sdin) || rowData.sdin == null)
            return "0.00";

        return parseFloat(rowData.sdin).toFixed(2)
    }
    const decimalSDout = (rowData) => {

        if (isNaN(rowData.sdout) || rowData.sdout == null)
            return "0.00";

        return parseFloat(rowData.sdout).toFixed(2)
    }
    const decimalTotal = (rowData) => {

        if (isNaN(rowData.total) || rowData.total == null)
            return "0.00";

        return parseFloat(rowData.total).toFixed(2)
    }
    const decimalshare = (rowData) => {

        if (isNaN(rowData.share) || rowData.share == null)
            return "0.00";

        return parseFloat(rowData.share).toFixed(2)
    }
    const decimalsub = (rowData) => {

        if (isNaN(rowData.subs) || rowData.subs == null)
            return "0.00";

        return parseFloat(rowData.subs).toFixed(2)
    }
    const decimalLoan = (rowData) => {

        if (isNaN(rowData.loan) || rowData.loan == null)
            return "0.00";

        return parseFloat(rowData.loan).toFixed(2)
    }
    const decimalSd = (rowData) => {

        if (isNaN(rowData.sd) || rowData.sd == null)
            return "0.00";

        return parseFloat(rowData.sd).toFixed(2)
    }
    const decimalBr = (rowData) => {

        if (isNaN(rowData.borrow) || rowData.borrow == null)
            return "0.00";

        return parseFloat(rowData.borrow).toFixed(2)
    }
    const decimalEnt = (rowData) => {

        if (isNaN(rowData.ent) || rowData.ent == null)
            return "0.00";

        return parseFloat(rowData.ent).toFixed(2)
    }
    const chequeno = (rowData) => {
        console.log(rowData)
        return rowData.bankname + " " +rowData.chequeno
    }

    useEffect(() => {
        if (formPayload['RI'].receipt_type == "specific_deposit") {
            setColumneVisible([
                { field: "memberid", header: "Member #", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "memberid", datatype: "numeric" },
                { field: "memberName", sortable: true, header: "MemberName", filterField: "member.membername", filter: true, filterPlaceholder: "Search", visible: true, frozen: true, filterElement: CustomFilter, alignFrozen: "right" },
                { field: "transdate", sortable: true, header: "Transaction Date", filterField: "transdate", filter: true, filterPlaceholder: "Search", visible: true, },
                { field: "bankdeposit", sortable: true, header: "Bank Deposit", filterField: "bankdeposit", filter: true, filterPlaceholder: "Search", visible: true, frozen: true, filterElement: CustomFilter, alignFrozen: "right"},
                { field: "chequeno", sortable: true, header: "Cheque Number", filterField: "chequeno", filter: true, filterPlaceholder: "Search", visible: true, frozen: true, filterElement: CustomFilter, alignFrozen: "right"},
                { field: "narration", sortable: true, header: "Narration", filterField: "narration", filter: true, filterPlaceholder: "Search", visible: true, },
                { field: "sdin", sortable: true, header: "SD IN", filterField: "sdin", filter: true, filterPlaceholder: "Search", visible: true, body: decimalSDin },
                { field: "sdout", sortable: true, header: "SD Out", filterField: "sdout", filter: true, filterPlaceholder: "Search", visible: true, body: decimalSDout },
            ])
            setGlobalFilterFields(["transdate", "memberid", "member.membername", "transamount",])
            setFilters({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                masterid: { value: null, matchMode: FilterMatchMode.CONTAINS },
                memberid: { value: null, matchMode: FilterMatchMode.CONTAINS },
                //"member.membername": { value: null, matchMode: FilterMatchMode.CONTAINS },
                transdate: { value: null, matchMode: FilterMatchMode.CONTAINS },
                narration: { value: null, matchMode: FilterMatchMode.CONTAINS },
                sdin: { value: null, matchMode: FilterMatchMode.CONTAINS },
                sdout: { value: null, matchMode: FilterMatchMode.CONTAINS },
            })


        }
        else if (formPayload['RI'].receipt_type == "detailed") {
            //console.log("??????????????????????????entered", formattedData)
            setColumneVisible(
                [
                    { field: "id", header: "RN NO", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "id", datatype: "numeric" },
                    { field: "masterid", header: "Bulk No", sortable: true, filter: true, filterPlaceholder: "Search", visible: false, frozen: false, filterElement: CustomFilter, filterField: "masterid", datatype: "numeric" },
                    { field: "memberid", header: "M#", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "memberid", datatype: "numeric" },
                    { field: "memberName", header: "Member Name", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "memberName", datatype: "numeric" },
                    { field: "chequeno", sortable: true, header: "Cheque Number", filterField: "chequeno", filter: true, filterPlaceholder: "Search", visible: true, frozen: true, filterElement: CustomFilter, alignFrozen: "right"},
                    { field: "bankdeposit", sortable: true, header: "Bank Deposit", filterField: "chequeno", filter: true, filterPlaceholder: "Search", visible: true, frozen: true, filterElement: CustomFilter, alignFrozen: "right"},
                    { field: "narration", header: "Narration", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "narration", datatype: "numeric" },
                    { field: "share", header: "Share", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "share", datatype: "numeric", body: decimalshare },
                    { field: "subs", header: "Subscription", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "subs", datatype: "numeric", body: decimalsub },
                    { field: "loan", header: "Loan", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "loan", datatype: "numeric", body: decimalLoan },
                    { field: "sd", header: "SD", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "sd", datatype: "numeric", body: decimalSd },
                    { field: "borrow", header: "Borrowings", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "borrow", datatype: "numeric", body: decimalBr },
                    { field: "ent", header: "Ent Fee", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "ent", datatype: "numeric", body: decimalEnt },])
                    // { field: "headcount", header: "Head Count", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "headcount", datatype: "numeric" }])

            // { field: "transamount", header: "SD", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "memberid", datatype: "numeric",body:summeryShareTemplate },
            setFilters({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: { value: null, matchMode: FilterMatchMode.CONTAINS },
                masterid: { value: null, matchMode: FilterMatchMode.CONTAINS },
                memberid: { value: null, matchMode: FilterMatchMode.CONTAINS },
                memberName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                narration: { value: null, matchMode: FilterMatchMode.CONTAINS },
                share: { value: null, matchMode: FilterMatchMode.CONTAINS },
                subs: { value: null, matchMode: FilterMatchMode.CONTAINS },
                loan: { value: null, matchMode: FilterMatchMode.CONTAINS },
                sd: { value: null, matchMode: FilterMatchMode.CONTAINS },
                borrow: { value: null, matchMode: FilterMatchMode.CONTAINS },
                ent: { value: null, matchMode: FilterMatchMode.CONTAINS }
            })



            setGlobalFilterFields(["id", "masterid", "memberid", "memberName", "narration", "share", "subs", "loan", "sd", "borrow", "ent"])

        }
        else if (formPayload['RI'].receipt_type == "summery") {
            setColumneVisible([
                { field: "transdate", header: "Reciept Date", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "transdate" },
                { field: "chequeno", sortable: true, header: "Cheque Number", filterField: "chequeno", filter: true, filterPlaceholder: "Search", visible: true, frozen: true, filterElement: CustomFilter, alignFrozen: "right" },
                { field: "bankdeposit", sortable: true, header: "Bank Deposit", filterField: "chequeno", filter: true, filterPlaceholder: "Search", visible: true, frozen: true, filterElement: CustomFilter, alignFrozen: "right" },
                { field: "masterid", sortable: true, header: "Bulk RN", filterField: "masterid", filter: true, filterPlaceholder: "Search", visible: true, },
                { field: "narration", header: "Narration", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "narration", datatype: "numeric" },
                { field: "estate", sortable: true, header: "Estate Name", filterField: "estate", filter: true, filterPlaceholder: "Search", visible: true, },
                { field: "state", sortable: true, header: "State", filterField: "state", filter: true, filterPlaceholder: "Search", visible: true },
                { field: "total", sortable: true, header: "Total", filterField: "total", filter: true, filterPlaceholder: "Search", visible: true, body: decimalTotal },
                { field: "share", sortable: true, header: "SH", filterField: "share", filter: true, filterPlaceholder: "Search", visible: true, body: decimalshare },
                { field: "subs", sortable: true, header: "SB", filterField: "subs", filter: true, filterPlaceholder: "Search", visible: true, body: decimalsub },
                { field: "loan", sortable: true, header: "Loan", filterField: "loan", filter: true, filterPlaceholder: "Search", visible: true, body: decimalLoan },
                { field: "sd", sortable: true, header: "SD", filterField: "sd", filter: true, filterPlaceholder: "Search", visible: true, body: decimalSd },
                { field: "borrow", sortable: true, header: "BR", filterField: "borrow", filter: true, filterPlaceholder: "Search", visible: true, body: decimalBr },
                { field: "ent", sortable: true, header: "EF", filterField: "ent", filter: true, filterPlaceholder: "Search", visible: true, },
                { field: "sh_hc", header: "SH_HC", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "sh_hc", datatype: "numeric" },
                { field: "sb_hc", header: "SB_HC", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "sb_hc", datatype: "numeric" },
                { field: "ln_hc", header: "LN_HC", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "ln_hc", datatype: "numeric" },
                { field: "ef_hc", header: "EF_HC", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "ef_hc", datatype: "numeric" },
                { field: "sd_hc", header: "SD_HC", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "sd_hc", datatype: "numeric" },
                { field: "br_hc", header: "BR_HC", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "br_hc", datatype: "numeric" },
                { field: "headcount", header: "Total (HC)", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, filterField: "headcount", datatype: "numeric" },

            ])
            setGlobalFilterFields(["transdate", "chequeno", "masterid", "estate", "state", "total", "share", "subs", "loan", "sd", "borrow", "ent"])
            setFilters({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                transdate: { value: null, matchMode: FilterMatchMode.CONTAINS },
                chequeno: { value: null, matchMode: FilterMatchMode.CONTAINS },
                masterid: { value: null, matchMode: FilterMatchMode.CONTAINS },
                estate: { value: null, matchMode: FilterMatchMode.CONTAINS },
                state: { value: null, matchMode: FilterMatchMode.CONTAINS },
                total: { value: null, matchMode: FilterMatchMode.CONTAINS },
                share: { value: null, matchMode: FilterMatchMode.CONTAINS },
                subs: { value: null, matchMode: FilterMatchMode.CONTAINS },
                loan: { value: null, matchMode: FilterMatchMode.CONTAINS },
                sd: { value: null, matchMode: FilterMatchMode.CONTAINS },
                borrow: { value: null, matchMode: FilterMatchMode.CONTAINS },
                ent: { value: null, matchMode: FilterMatchMode.CONTAINS }
            })

        }


    }, [formPayload])

    const formattingData = () => {
        let formattedArray = []

        if (formPayload['RI'].receipt_type == "detailed" && receiptIssued.length > 0) {
            const sorted = [...receiptIssued]
            sorted.sort((a, b) => a.transmasterid - b.transmasterid)

            sorted.forEach((item) => {
                let obj = { id: "",memberid:"", bulkno: "",  memberName: "", narration: "", loanno:"",share: 0.00, subs: 0.00, sd: 0.00, loan: 0.00, borrow: 0.00, ent: 0.00,bulkno:"",transdate:"", chequeno: "", estate: "", state: "", masterid: ""}
                obj.id = item.transdetailid
                obj.memberid = item.member.memberid
                obj.masterid =  item.transmasterid
                obj.memberName = item.member.membername
                obj.narration = item.narration
                obj.loanno = item.loanno !==null ? item.loanno : ""
                obj.share = shareTemplate(item)
                obj.subs = subsTemplate(item)
                obj.sd = sDTemplate(item)
                obj.loan = loanTemplate(item)
                obj.borrow = BRTTemplate(item)
                obj.ent = entTemplate(item)
                obj.bulkno = item.transmasterid
                obj.transdate = formattingdate(item.transdate)
                obj.state = item.member.state
                obj.estate = item.member.estate
                obj.bankdeposit = item.bankdeposit!==null ? item.bankdeposit : ""
                obj.chequeno = item.bankname?.trim() + " " + item.chequeno
                formattedArray.push(obj)
            })
            setFormattedData(formattedArray)
        }
        else if (formPayload['RI'].receipt_type == "summery" && receiptIssued.length > 0) {

            // let obj={id:"",chequeno:"",estate:"",state:"",transdate:"",masterid:"",memberid:"",memberName:"",narration:"",share:0.00,subs:0.00,sd:0.00,loan:0.00,borrow:0.00,ent:0.00}

            const sorted = [...receiptIssued]
            // sorted.sort((a, b) =>)
            sorted.sort((a, b) => new Date(a.transdate).getTime() - new Date(b.transdate).getTime())
            let seen = {};
            let duplicates = [];
            let unique = [];
            // const sorted=[...withdrawalData]
            // sorted.sort((a, b) => a.state.executive.localeCompare(b.state));
            //console.log(sorted)
            sorted.forEach((item) => {
                const value = item.transmasterid;

                if (seen[value]) {
                    duplicates.push(item);
                } else {
                    seen[value] = true;
                    unique.push(item.transmasterid);
                }
            });
            //console.log(unique);

            for (let i = 0; i < unique.length; i++) {
                let obj = { id: "", chequeno: "", typeid: "", estate: "", state: "", transdate: "", masterid: "", memberid: "", memberName: "", narration: "", share: 0.00, subs: 0.00, sd: 0.00, loan: 0.00, borrow: 0.00, ent: 0.00, sh_hc: 0, sb_hc: 0, ln_hc: 0, ef_hc: 0, sd_hc: 0, br_hc: 0 }


                sorted.forEach(item => {


                    if (unique[i] === item.transmasterid) {
                        obj.id = item.transdetailid
                        obj.transdate = formattingdate(item.transdate)
                        // obj.state = item.state
                        // obj.estate = item.estate
                        obj.masterid = item.transmasterid
                        obj.narration = item.narration
                        obj.headcount = item.headcount
                        obj.chequeno = item.bankname?.trim() + " " + item.chequeno
                        obj.bankdeposit = item.bankdeposit
                        obj.estate = item.estate
                        obj.state = item.state
                        obj.total = item.bulkamount
                        obj.typeid = item.transtypeid
                        obj.bulkamount = item.bulkamount
                        if ((item.transtypeid == 1)||(item.transtypeid == 11)) {
                            obj.share += parseFloat(item.transamount)
                            obj.sh_hc++
                        }
                        else if ((item.transtypeid == 2)||(item.transtypeid == 12)) {
                            obj.subs += parseFloat(item.transamount)
                            obj.sb_hc++
                        }
                        else if (item.transtypeid == 6) {
                            obj.sd += parseFloat(item.transamount)
                            obj.sd_hc++
                        }
                        else if (item.transtypeid == 5) {
                            obj.loan += parseFloat(item.transamount)
                            obj.ln_hc++
                        }
                        else if (item.transtypeid == 3) {
                            obj.borrow += parseFloat(item.transamount)
                            obj.br_hc++
                        }
                        else if (item.transtypeid == 7) {
                            obj.ent += parseFloat(item.transamount)
                            obj.ef_hc++
                        }

                    }

                })
                formattedArray.push(obj)

            }

            setFormattedData(formattedArray)
        }
        else if (formPayload['RI'].receipt_type == "specific_deposit" && receiptIssued.length > 0) {


            receiptIssued.forEach(item => {
                let obj = { memberid: "", memberName: "", transdate: "", narration: "", sdin: 0.00, sdout: 0.00 ,state:"",estate: "",chequeno: "",transdetailid: "",sd:""}
                obj.memberid = item.memberid
                obj.memberName = item.member.membername
                obj.narration = item.narration
                obj.headcount = item.headcount
                obj.chequeno = item.bankname?.trim() + " " + item.chequeno
                obj.bankdeposit = item.bankdeposit
                obj.transdate = formattingdate(item.transdate)
                obj.narration = item.narration
                obj.transdetailid = item.transdetailid
                obj.sd = item.loanno!==null ? item.loanno:""
                obj.state = item.member.state
                obj.estate = item.member.estate
                obj.sdin = SdInAmount(item)
                obj.sdout = SdOutAmount(item)
                formattedArray.push(obj)

            })
            setFormattedData(formattedArray)
        }


    }


    const clearFilter = () => {

        setFilters(
            {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                membername: { value: null, matchMode: FilterMatchMode.CONTAINS },
                transmasterid: { value: null, matchMode: FilterMatchMode.CONTAINS },
                estate: { value: null, matchMode: FilterMatchMode.CONTAINS },
                "memmber.state": { value: null, matchMode: FilterMatchMode.CONTAINS },
                transamount: { value: null, matchMode: FilterMatchMode.CONTAINS },
                memberid: { value: null, matchMode: FilterMatchMode.CONTAINS },
                bulkamount: { value: null, matchMode: FilterMatchMode.CONTAINS },


            }
        )
        setGlobalFilterValue("")
        setFilterState(false)
    }

    const formattingdate = (date) => {
        if (date) {
            const originalDate = new Date(date);
            const year = originalDate.getUTCFullYear();
            const month = String(originalDate.getUTCMonth() + 1).padStart(2, '0');
            const day = String(originalDate.getUTCDate()).padStart(2, '0');

            let formattedDate = `${day}-${month}-${year}`;
            return formattedDate;
        }
        else {
            return date
        }

    }


    //console.log(formPayload)
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        //console.log(_filters)

        _filters['global'].value = value;
        //console.log(_filters)

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {

            setSelectAll(true);
            setSelectedCustomers(receiptIssued);

        } else {
            setSelectAll(false);
            setSelectedCustomers([]);
        }
    };
    const onSelectionChange = (event) => {
        const value = event.value;
        //console.log(value)
        setSelectedCustomers(value);
        //console.log(selectedCustomers)
        setSelectAll(value.length === totalRecords);
    };
    useEffect(() => {
        //console.log("---inside NMC---", receiptIssued)
    }, [receiptIssued])
    const headerTemplate = (data) => {
        if (formPayload['RI'].receipt_type == "detailed" && formattedData.length > 0) {
            return (
                <div className="flex align-items-center gap-2">

                    <span className="font-bold">Bulk Number: {data.masterid}</span>
                </div>
            );


        }
        else if (formPayload['RI'].receipt_type == "summery" && formattedData.length > 0) {
            return (
                <div className="flex align-items-center gap-2">

                    <span className="font-bold">Date: {data.transdate}</span>
                </div>
            );
        }

    };

    const addCurrencyAndCommafy = (num) => {

        let str = parseFloat(num).toFixed(2).toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        return "RM " + str.join('.');

    }

    const calculateFooter = (data, from) => {
         //console.log(data)
        if (from == "total") {
            let total = 0.00;
            formattedData.forEach(item => {
                // console.log (item)
                if (formPayload['RI'].receipt_type == "detailed") {
                    if (item.masterid == data.masterid) {
                        //  console.log("????????????????inside share",item.masterid,masterid,item.typeid)
                        // if (isNaN(item.total)){
                        //     total+=0.00}
                        // else{
                        total += parseFloat(item.total)

                    }

                }
                else {
                    if (item.transdate == data.transdate) {
                        //  console.log("????????????????inside share",item.masterid,masterid,item.typeid)
                        // if (isNaN(item.total)){
                        //     total+=0.00}
                        // else{
                        total += parseFloat(item.total)

                    }
                }

            })

            return total;

        }
        else if (from == "sh") {
            let total = 0.00;
            formattedData.forEach(item => {
                //    console.log (item)
                if (formPayload['RI'].receipt_type == "detailed") {
                    if (item.masterid == data.masterid) {
                        //  console.log("????????????????inside share",item.masterid,masterid,item.typeid)
                        // if (isNaN(item.share)){
                        //     total+=0.00}
                        // else{
                        total += parseFloat(item.share)

                    }

                }
                else {
                    if (item.transdate == data.transdate) {
                        //  console.log("????????????????inside share",item.masterid,masterid,item.typeid)
                        // if (isNaN(item.share)){
                        //     total+=0.00}
                        // else{
                        total += parseFloat(item.share)

                    }
                }



            })

            return total;

        }
        else if (from == "sb") {
            let total = 0;
            formattedData.forEach(item => {
                // console.log (item)
                if (formPayload['RI'].receipt_type == "detailed") {
                    if (item.masterid == data.masterid) {
                        //  console.log("????????????????inside share",item.masterid,masterid,item.typeid)
                        // if (isNaN(item.subs)){
                        //     total+=0.00}
                        // else{
                        total += parseFloat(item.subs)

                    }

                }
                else {
                    if (item.transdate == data.transdate) {
                        //  console.log("????????????????inside share",item.masterid,masterid,item.typeid)
                        // if (isNaN(item.subs)){
                        //     total+=0.00}
                        // else{
                        total += parseFloat(item.subs)

                    }
                }
            })

            return total;
        }
        else if (from == "loan") {
            let total = 0.00;
            formattedData.forEach(item => {
                // console.log (item)
                if (formPayload['RI'].receipt_type == "detailed") {
                    if (item.masterid == data.masterid) {
                        //  console.log("????????????????inside share",item.masterid,masterid,item.typeid)
                        // if (isNaN(item.loan)){
                        //     total+=0.00}
                        // else{
                        total += parseFloat(item.loan)

                    }

                }
                else {
                    if (item.transdate == data.transdate) {
                        //  console.log("????????????????inside share",item.masterid,masterid,item.typeid)
                        // if (isNaN(item.loan)){
                        //     total+=0.00}
                        // else{
                        total += parseFloat(item.loan)

                    }
                }
            })

            return total;
        }
        else if (from == "sd") {
            let total = 0.00;
            formattedData.forEach(item => {
                // console.log (item)
                if (formPayload['RI'].receipt_type == "detailed") {
                    if (item.masterid == data.masterid) {
                        //  console.log("????????????????inside share",item.masterid,masterid,item.typeid)
                        // if (isNaN(item.sd)){
                        //     total+=0.00}
                        // else{
                        total += parseFloat(item.sd)

                    }

                }
                else {
                    if (item.transdate == data.transdate) {
                        //  console.log("????????????????inside share",item.masterid,masterid,item.typeid)
                        // if (isNaN(item.sd)){
                        //     total+=0.00}
                        // else{
                        total += parseFloat(item.sd)

                    }
                }
            })

            return total;
        }
        else if (from == "br") {
            let total = 0.00;
            formattedData.forEach(item => {
                //console.log (item)
                if (formPayload['RI'].receipt_type == "detailed") {
                    if (item.masterid == data.masterid) {
                        //  console.log("????????????????inside share",item.masterid,masterid,item.typeid)
                        // if (isNaN(item.borrow)){
                        //     total+=0.00}
                        // else{
                        total += parseFloat(item.borrow)

                    }

                }
                else {
                    if (item.transdate == data.transdate) {
                        //  console.log("????????????????inside share",item.masterid,masterid,item.typeid)
                        // if (isNaN(item.borrow)){
                        //     total+=0.00}
                        // else{
                        total += parseFloat(item.borrow)

                    }
                }
            })

            return total;
        }
        else if (from == "ef") {
            let total = 0.00;
            formattedData.forEach(item => {
                // console.log (item)
                if (formPayload['RI'].receipt_type == "detailed") {
                    if (item.masterid == data.masterid) {
                        //  console.log("????????????????inside share",item.masterid,masterid,item.typeid)
                        // if (isNaN(item.ent)){
                        //     total+=0.00}
                        // else{
                        total += parseFloat(item.ent)

                    }

                }
                else {
                    if (item.transdate == data.transdate) {
                        //  console.log("????????????????inside share",item.masterid,masterid,item.typeid)
                        // if (isNaN(item.ent)){
                        //     total+=0.00}
                        // else{
                        total += parseFloat(item.ent)

                    }
                }
            })

            return total;
        }

    }

    const footerTemplate = (data) => {
        if (formPayload['RI'].receipt_type == "detailed") {
            let new_pos = columnVisible.filter(item => item.visible).map(item => (item.field))
            //console.log(new_pos)
            const position = new_pos.indexOf("share");


            return <>
                {new_pos.includes("share") && <td colSpan={`${position + 2}`} >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total Share: {addCurrencyAndCommafy(calculateFooter(data, "sh"))}</div>
                </td>}
                {new_pos.includes("subs") && <td >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total Subs: {addCurrencyAndCommafy(calculateFooter(data, "sb"))}</div>
                </td>}
                {new_pos.includes("loan") && <td >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total Loan: {addCurrencyAndCommafy(calculateFooter(data, "loan"))}</div>
                </td>}
                {new_pos.includes("sd") && <td >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total SD: {addCurrencyAndCommafy(calculateFooter(data, "sd"))}</div>
                </td>}
                {new_pos.includes("borrow") && <td >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total Borrowing: {addCurrencyAndCommafy(calculateFooter(data, "br"))}</div>
                </td>}
                {new_pos.includes("ent") && <td >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total ENT: {addCurrencyAndCommafy(calculateFooter(data, "ef"))}</div>
                </td>}

            </>

        }
        else if (formPayload['RI'].receipt_type == "summery") {
            // const keys = Object.keys(formattedData[0]);
            // const position = keys.indexOf("share");
            // console.log(keys)
            // console.log(position)
            // let visible=Object.keys(columnVisible[0])
            // console.log(visible)
            let new_pos = columnVisible.filter(item => item.visible).map(item => (item.field))
            const position = new_pos.indexOf("share");
            return <>
                {new_pos.includes("total") && <td colSpan={`${position + 1}`} >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total: {addCurrencyAndCommafy(calculateFooter(data, "total"))}</div>
                </td>}
                {new_pos.includes("share") && <td  >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total Share: {addCurrencyAndCommafy(calculateFooter(data, "sh"))}</div>
                </td>}
                {new_pos.includes("subs") && <td >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total Subs: {addCurrencyAndCommafy(calculateFooter(data, "sb"))}</div>
                </td>}
                {new_pos.includes("loan") && <td >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total Loan: {addCurrencyAndCommafy(calculateFooter(data, "loan"))}</div>
                </td>}
                {new_pos.includes("sd") && <td >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total SD: {addCurrencyAndCommafy(calculateFooter(data, "sd"))}</div>
                </td>}
                {new_pos.includes("borrow") && <td >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total BR: {addCurrencyAndCommafy(calculateFooter(data, "br"))}</div>
                </td>}
                {new_pos.includes("ent") && <td >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total EF: {addCurrencyAndCommafy(calculateFooter(data, "ef"))}</div>
                </td>}


            </>

        }


    };
    const exportExcel = (data) => {
        data = data.map((item, index) => {
            return {  SN: index + 1, ...item };
          });
          let filteredData
          let headers
          let requiredColumns
          if(formPayload['RI'].receipt_type=="summery"){
            requiredColumns = ['SN','state', 'estate','bankdeposit','masterid','transdate','chequeno',
              'narration','share','subs','loan','ent','sd','total','bulkamount','sh_hc','sb_hc','ln_hc','ef_hc','sd_hc','headcount'];

              const columnHeadersMap = {
                  SN: 'SN',
                  state: 'State',
                  estate: 'EstateName',
                  bankdeposit: 'Bank Deposit',
                  masterid: 'Bulk Rn',
                  transdate: 'RcptDate',
                  chequeno: 'ChqNo',
                  narration: 'Narration',
                  share: 'Share (RM)',
                  subs: 'Subs (RM)',
                  loan: 'Loan (RM)',
                  ent: 'E/FEE (RM)',
                  sd: 'SD (RM)',
                  total: 'Total Share + Subs (RM)',
                  bulkamount: 'Total Bulk Amount (RM)',
                  sh_hc: 'SH_HC',
                  sb_hc: 'SB_HC',
                  ln_hc: 'LN_HC',
                  ef_hc: 'EF_HC',
                  sd_hc: 'SD_HC',
                  headcount: 'Total (HC)',
                };
                 filteredData = data.map(obj => {
                  return requiredColumns.reduce((acc, key) => {
                    acc[key] = obj[key];
                    return acc;
                  }, {});
                }); 
      headers = requiredColumns.map(header => {
              return { 
                  v: columnHeadersMap[header], 
                  t: 's', 
                  s: { 
                      font: { bold: true,size: 10 } } };
            });
          }
          else if(formPayload['RI'].receipt_type=="detailed"){

            
            requiredColumns = ['SN','id','memberid','memberName','narration','loanno','share','subs','loan','sd'
            ,'borrow','ent','bulkno','transdate','state','estate','bankdeposit','chequeno'];

            const columnHeadersMap = {
                SN: 'SN',
                id:'RN',
                memberid: 'M#',
                memberName: 'Member Name',
                narration: 'Narration',
                loanno:"L#/SD#/BR#",
                share:'share',
                subs:'subscription',
                loan:'Loan',
                sd:'SD',
                borrow:'Borrowing',
                ent:'Ent Fee',
                bulkno:'Bulk Rn',
                transdate: 'Date',
                state: 'State',
                estate: 'EstateName',
                bankdeposit:'Bank Deposit',
                chequeno: 'Chq No',
              };
               filteredData = data.map(obj => {
                return requiredColumns.reduce((acc, key) => {
                  acc[key] = obj[key];
                  return acc;
                }, {});
              }); 
    headers = requiredColumns.map(header => {
            return { 
                v: columnHeadersMap[header], 
                t: 's', 
                s: { 
                    font: { bold: true,size: 10 } } };
          });
          }else{
            requiredColumns = ['SN','state', 'memberid','memberName','transdate','chequeno',
              'transdetailid','sd','narration','sdin','sdout','estate'];

              const columnHeadersMap = {
                  SN: 'SN',
                  state: 'State',
                  memberid: 'M#',
                  memberName: 'Membername',
                  transdate: 'Trans Date',
                  chequeno: 'ChqNo',
                  transdetailid: 'RN',
                  sd: 'SD#',
                  narration: 'Narration',
                  sdin: 'SD IN',
                  sdout: 'SD OUT',
                  estate: 'EstateName',
                };
                 filteredData = data.map(obj => {
                  return requiredColumns.reduce((acc, key) => {
                    acc[key] = obj[key];
                    return acc;
                  }, {});
                }); 
      headers = requiredColumns.map(header => {
              return { 
                  v: columnHeadersMap[header], 
                  t: 's', 
                  s: { 
                      font: { bold: true,size: 10 } } };
            });
          }
               
     
      const additionalRow1 = ['','KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)']
      const additionalRow2 = []
      const additionalRow3 = ['',formPayload['RI'].receipt_type=="summery"?'Receipt Issued Report (Summary)':formPayload['RI'].receipt_type=="detailed"?"Receipt Issued Report (Detailed)":'Specific Deposit Report']
      const additionalRow4 = ['',`Date Range : ${formattingdate(formPayload['RI'].dr[0])+ " TO " +formattingdate(formPayload['RI'].dr[1])}`]
      const additionalRow5 = ['',`Estate : ${formPayload['RI'].estate}`]
      const additionalRow6 = ['',`Region : ${formPayload['RI'].region}`]
      const additionalRow7 = ['',`State  : ${formPayload['RI'].state==""?"ALL":formPayload['RI'].state}`]
      const additionalRow8 = ['',`Postal Code : ${formPayload['RI'].postal_code}`]
      const additionalRow9 = []

      
    
        // Convert array of objects to array of arrays (2D array)
        const dataArray = [additionalRow1,additionalRow2,additionalRow3,additionalRow4,additionalRow5,additionalRow6,additionalRow7,additionalRow8,additionalRow9, headers, ...filteredData.map(obj =>
      requiredColumns.map(key => {
        let value;
        const amountRelatedKeys = ["share","subs","loan","sd","borrow","ent","total","sdin","sdout","bulkamount"]
        if(amountRelatedKeys.includes(key)){
            value = parseInt(parseFloat(obj[key]).toFixed(2).split('.')[1]) === 0?parseInt(obj[key]):obj[key]
        }
        else
            value = obj[key]
        return {
          v: value,
          t: typeof value === 'number' ? 'n' : 's',
          s: {
            alignment: { horizontal: typeof value === 'number' ? 'right' : 'left' },
            font: { size: 8 , name: 'Times New Roman', bold: key == requiredColumns[0] } // Set font size to 8
          }
        };
      })
    )];
    
        // Convert data to worksheet
        const ws = XLSX.utils.aoa_to_sheet(dataArray);
    
        // Create workbook and add the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
        // Generate XLSX file and trigger download
        XLSX.writeFile(wb, formPayload['RI'].receipt_type=="summery"?'Receipt Issued Report (Summary).xlsx':formPayload['RI'].receipt_type=="detailed"?"Receipt Issued Report (Detailed).xlsx":'Specific Deposit Report.xlsx');
    }
    const shTotal = () => {
        let total = 0;

        for (let row of formattedData) {

            if (isNaN(row.share) || row.share == null)
                total += 0;
            else
                total += parseFloat(row.share);
        }

        return total;
    };

    const sbTotal = () => {
        let total = 0;

        for (let row of formattedData) {

            if (isNaN(row.subs) || row.subs == null)
                total += 0;
            else
                total += parseFloat(row.subs);
        }

        return total;
    };
    const sdTotal = () => {
        let total = 0;

        for (let row of formattedData) {

            if (isNaN(row.sd) || row.sd == null)
                total += 0;
            else
                total += parseFloat(row.sd);
        }

        return total;

    }
    const borrowTotal = () => {
        let total = 0;

        for (let row of formattedData) {

            if (isNaN(row.borrow) || row.borrow == null)
                total += 0;
            else
                total += parseFloat(row.borrow);
        }

        return total;

    }
    const loanTotal = () => {
        let total = 0;

        for (let row of formattedData) {

            if (isNaN(row.loan) || row.loan == null)
                total += 0;
            else
                total += parseFloat(row.loan);
        }

        return total;

    }
    const entTotal = () => {
        let total = 0;

        for (let row of formattedData) {

            if (isNaN(row.ent) || row.ent == null)
                total += 0;
            else
                total += parseFloat(row.ent);
        }

        return total;

    }
    const Total = () => {
        let total = 0;

        for (let row of formattedData) {

            if (isNaN(row.total) || row.total == null)
                total += 0;
            else
                total += parseFloat(row.total);
        }

        return total;

    }
    const SdOutTotal = () => {
        let total = 0.00;

        for (let row of receiptIssued) {
            if (row.transtypeid == 24) {

                if (isNaN(row.transamount) || row.transamount == null)
                    total += 0;
                else
                    total += parseFloat(row.transamount);
            }

        }

        return total;

    }
    const SdInTotal = () => {
        let total = 0.00;

        for (let row of receiptIssued) {
            //console.log(row.transtypeid == 6);
            if (row.transtypeid == 6) {

                if (isNaN(row.transamount) || row.transamount == null)
                    total += 0;
                else
                    total += parseFloat(row.transamount);
            }
        }

        return total;

    }
    const grandTotal = () => {

        let total = 0;

        if (formPayload['RI'].receipt_type == "detailed" || formPayload['RI'].receipt_type == "summery") {

            total = parseFloat(shTotal()) + parseFloat(sbTotal()) + parseFloat(loanTotal()) + parseFloat(sdTotal()) + parseFloat(borrowTotal()) + parseFloat(entTotal());
            total = addCurrencyAndCommafy(total);
        }
        else {

            total = parseFloat(SdInTotal()) + parseFloat(SdOutTotal());
            total = addCurrencyAndCommafy(total);
        }

        if (formPayload['RI'].receipt_type == "detailed") {

            return (
                <span>
                    <span style={{ fontWeight: 'bold' }}>Grand Total :</span>{" "}
                    {total}
                </span>)


        }
        else if (formPayload['RI'].receipt_type == "summery") {
            return (<span>
                <span style={{ fontWeight: 'bold' }}>Grand Total :</span>{" "}
                {total}
            </span>)
        }
        else {
            return (<span>
                <span style={{ fontWeight: 'bold' }}>Grand Total :</span>{" "}
                {total}
            </span>)
        }


    }
    const sbTotalHead = () => {
        let total = 0;
        if (formPayload['RI'].receipt_type == "detailed") {
            for (let row of formattedData) {
                if (row.typeid == 2) {
                    total += 1
                }

            }
        }
        if (formPayload['RI'].receipt_type == "summery") {
            for (let row of receiptIssued) {
                if (row.transtypeid == 2) {
                    total += 1
                }
            }
        }

        return total;
    }
    const shTotalHead = () => {
        let total = 0;
        if (formPayload['RI'].receipt_type == "detailed") {
            for (let row of formattedData) {
                if (row.typeid == 1) {
                    total += 1
                }

            }
        }
        if (formPayload['RI'].receipt_type == "summery") {
            for (let row of receiptIssued) {
                if (row.transtypeid == 1) {
                    total += 1
                }
            }
        }

        return total;

    }
    const loanTotalHead = () => {
        let total = 0;
        if (formPayload['RI'].receipt_type == "detailed") {
            for (let row of formattedData) {
                if (row.typeid == 5) {
                    total += 1
                }

            }
        }
        if (formPayload['RI'].receipt_type == "summery") {
            for (let row of receiptIssued) {
                if (row.transtypeid == 5) {
                    total += 1
                }
            }
        }

        return total;
    }
    const sdTotalHead = () => {
        let total = 0;
        if (formPayload['RI'].receipt_type == "detailed") {
            for (let row of formattedData) {
                if (row.typeid == 6) {
                    total += 1
                }

            }
        }
        if (formPayload['RI'].receipt_type == "summery") {
            for (let row of receiptIssued) {
                if (row.transtypeid == 6) {
                    total += 1
                }
            }
        }

        return total;
    }
    const borrowTotalHead = () => {
        let total = 0;
        if (formPayload['RI'].receipt_type == "detailed") {
            for (let row of formattedData) {
                if (row.typeid == 3) {
                    total += 1
                }

            }
        }
        if (formPayload['RI'].receipt_type == "summery") {
            for (let row of receiptIssued) {
                if (row.transtypeid == 3) {
                    total += 1
                }
            }
        }

        return total;
    }
    const entTotalHead = () => {
        let total = 0;
        if (formPayload['RI'].receipt_type == "detailed") {
            for (let row of formattedData) {
                if (row.typeid == 7) {
                    total += 1
                }

            }
        }
        if (formPayload['RI'].receipt_type == "summery") {
            for (let row of receiptIssued) {
                if (row.transtypeid == 7) {
                    total += 1
                }
            }
        }

        return total;
    }

    const TotalHeadCount = () => {
        if (formPayload['RI'].receipt_type == "detailed") {
            return (
                <span>
                    <span style={{ fontWeight: 'bold' }}>Total Head Count : </span>{" "}
                    {shTotalHead() + sbTotalHead() + loanTotalHead() + sdTotalHead() + borrowTotalHead() + entTotalHead()}
                </span>)


        }
        else if (formPayload['RI'].receipt_type == "summery") {
            return (
                <span>
                    <span style={{ fontWeight: 'bold' }}>Total Head Count : </span>{" "}
                    {shTotalHead() + sbTotalHead() + loanTotalHead() + sdTotalHead() + borrowTotalHead() + entTotalHead()}
                </span>)
        }

    }

    const shaTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Share Total : </span>{addCurrencyAndCommafy(shTotal())}
            </span>
        )
    }

    const subTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Subs Total : </span>{addCurrencyAndCommafy(sbTotal())}
            </span>
        )
    }

    const lTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Loan Total : </span>{addCurrencyAndCommafy(loanTotal())}
            </span>
        )
    }

    const SDTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>SD Total : </span>{addCurrencyAndCommafy(sdTotal())}
            </span>
        )
    }

    const BorrowTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Borrow Total : </span>{addCurrencyAndCommafy(borrowTotal())}
            </span>
        )
    }

    const EntTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>ENT Total : </span>{addCurrencyAndCommafy(entTotal())}
            </span>
        )
    }

    const SHTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Share Total Head Count : </span>{shTotalHead()}
            </span>
        )
    }

    const SBTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Subs Total Head Count : </span>{sbTotalHead()}
            </span>
        )
    }

    const LoanTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Loan Total Head Count : </span>{loanTotalHead()}
            </span>
        )
    }

    const SDHTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>SD Total Head Count : </span>{sdTotalHead()}
            </span>
        )
    }

    const BHTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Borrow Total Head Count : </span>{borrowTotalHead()}
            </span>
        )
    }

    const EHTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Ent Total Head Count : </span>{entTotalHead()}
            </span>
        )
    }

    const MTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Total : </span>{addCurrencyAndCommafy(Total())}
            </span>
        )
    }


    const footerGroupDetailed = (


        <ColumnGroup>
            <Row>
                <Column footer={grandTotal} colSpan={5} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={shaTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={subTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={lTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={SDTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={BorrowTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={EntTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />

            </Row>

            {/* <Row>
                <Column footer={TotalHeadCount} colSpan={5} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={SHTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={SBTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={LoanTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={SDHTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={BHTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={EHTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />

            </Row> */}
        </ColumnGroup>
    );
    const footGroupSummary = (
        <ColumnGroup>
            <Row>
                <Column footer={grandTotal} colSpan={5} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={MTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={shaTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={subTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={lTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={SDTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={BorrowTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={EntTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
            </Row>
            <Row>
                <Column footer={TotalHeadCount} colSpan={7} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={SHTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={SBTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={LoanTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={SDHTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={BHTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={EHTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />

            </Row>
        </ColumnGroup>

    )
    const footGroupSpecificDep = (
        <ColumnGroup>
            <Row>
                <Column footer="Grand Total" colSpan={5} footerStyle={{ textAlign: 'right' }} />
                <Column footer={addCurrencyAndCommafy(SdInTotal())} footerStyle={{ fontWeight: 'normal' }} />
                <Column footer={addCurrencyAndCommafy(SdOutTotal())} footerStyle={{ fontWeight: 'normal' }} />
            </Row>
        </ColumnGroup>
    )
    return (
        <Box className="fulldiv" sx={{ width: isSidebarOpen?"calc(100vw - 319px)":"calc(100vw - 95px)", height: "calc(100vh - 64px)", }} >
            <Dialog visible={modalOpen} style={{ width: '790px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={false} closable={false} resizable={false} >
                <Form formAlone={true} api="RI" />
            </Dialog>
            <Box sx={{ marginLeft: "24px", padding: "8px 0px 8px 0px", marginRight: "16px", height: "93vh", }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", gap: "12px", }}>
                        <SummarizeOutlinedIcon sx={{ fontSize: "32px", color: "#288d2c" }} />
                        <Box>
                            <Typography sx={{ fontSize: "20px", fontWeight: "600", lineHeight: "28px", color: "#101010" }}>Receipt Issued {formPayload['RI'].receipt_type == "detailed" ? "Detailed" : formPayload['RI'].receipt_type == "specific_deposit" ? "Specific Deposit" : formPayload['RI'].receipt_type == "summery" && "Summary"} Report</Typography>
                            <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#64748B" }}>Found  <span style={{ fontWeight: "700" }}>{formPayload['RI'].receipt_type == "specific_deposit" ? receiptIssued.length : formattedData.length}</span> records on <span style={{ fontWeight: "700" }}>{formattingdate(formPayload['RI']?.dr[0])}</span> to <span style={{ fontWeight: "700" }}>{formattingdate(formPayload['RI']?.dr[1])}</span> with applied Configure</Typography>
                        </Box>

                    </Box>
                    <Box sx={{ alignSelf: "end" }}>
                        <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#288d2c" }, gap: "8px", fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "201px", height: "48px" }} startIcon={<SettingsIcon sx={{ fontSize: "24px", }} />} onClick={() => dispatch(updateModalOpen(true))}>Data Re-Configure</Button>
                    </Box>

                </Box>
                <Box sx={{ marginTop: "24px", }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between",width:"100%",flexWrap:"wrap" }}>


                        <Box sx={{ display: "flex", alignItems: "center" }}>

                            <Box>

                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText style={{ width: "655px", height: "36px" }} placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} />
                                </span>
                            </Box>
                            <Box sx={{ color: "#64748B", width: "178px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "16px", alignSelf: "end", cursor: "pointer", textTransform: "none", gap: "8px" }} onClick={clearFilter}>
                                <ClearAllIcon sx={{ fontSize: "24px", color: "#CBD5E1" }} />
                                <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#CBD5E1" }}></Typography>
                                Clear Filter
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "16px", alignSelf: 'end' }}>
                            <Box sx={{ padding: "12px" }}> {filterState ? <FilterAltOffIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(false)} /> : <FilterAltIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(true)} />}</Box>
                            <Box sx={{ padding: "12px" }}><DownloadIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={()=>exportExcel(formattedData)} /> </Box>
                            {/* <CSVLink data={selectedCustomers.length > 0 ? selectedCustomers : formattedData} headers={exportCSV} filename={'table-export.csv'}> <Box sx={{ padding: "12px" }}><DownloadIcon sx={{ color: "#64748B", fontSize: "24px" }} /> </Box></CSVLink> */}
                            <Box sx={{ padding: "12px" }}><TableChartIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => { setArrangeTable(true); setSeperatedColumn(columnVisible) }} /> </Box>
                            {/* */}
                        </Box>

                    </Box>

                </Box>
                <Box sx={{ marginTop: "24px", }}>
                    <div className="card">
                        <DataTable ref={dt} glo groupField="matsterid" value={formattedData} sortMode="single" footerColumnGroup={formPayload['RI'].receipt_type == "detailed" ? footerGroupDetailed : formPayload['RI'].receipt_type == "summery" ? footGroupSummary : formPayload['RI'].receipt_type == "specific_deposit" ? footGroupSpecificDep : null} filters={filters} paginator rows={10} dataKey="transdetailid" style={{ backgroundColor: "#F1F5F9" }} scrollable scrollHeight="650px" filterDisplay={filterState ? "row" : ""} loading={loading} columnResizeMode="expand" rowGroupMode={formPayload['RI'].receipt_type == "specific_deposit" ? "subheader" : "subheader"} removableSort resizableColumns groupRowsBy={formPayload['RI'].receipt_type == "detailed" ? "masterid" : "transdate"}
                            selection={selectedCustomers} selectionMode="checkbox" onSelectionChange={onSelectionChange} selectAll={selectAll} filterIcon={false} onSelectAllChange={onSelectAllChange} emptyMessage="No customers found." globalFilterFields={globalFilterFields} rowGroupHeaderTemplate={headerTemplate} rowGroupFooterTemplate={formPayload['RI'].receipt_type == "detailed" || formPayload['RI'].receipt_type == "summery" ? footerTemplate : null}  >{/* rowGroupFooterTemplate={formPayload['RI'].receipt_type=="detailed"?footerTemplate: null} */}

                            <Column styleClass="custom-column-style" selectionMode="multiple" headerStyle={{ width: "100px", background: "#F1F5F9" }} />

                            {columnVisible.filter((col, ind) => col.visible).map(col => <Column className="custom-column-style" style={{ width: "500px" }} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} alignFrozen={col.alignFrozen} footer={col.footer} filter={col.filter} dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                        </DataTable>
                    </div>
                </Box>


            </Box>
            <Dialog visible={arrangeTable} style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={true} closable={false} resizable={false} >


                <Box style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", padding: "30px 30px 0px 30px", borderRadius: "0px", display: "flex", flexDirection: "column", gap: "24px", }}>
                    <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#f2f6fc" }}>
                        <TableChartIcon sx={{ fontSize: "24px", color: "#288d2c" }} />
                        <Typography sx={{ fontSize: "18px", fontWeight: 600, lineHeight: "28px" }}>Table Column Configure</Typography>
                    </Box>
                    <Box>


                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText style={{ width: "461px", height: "36px" }} placeholder="Search" onChange={onGlobalFilterChange} />
                        </span>

                    </Box>
                    <Box sx={{ height: "461px", overflowY: "scroll", display: "flex", flexDirection: "column", gap: "20px" }}>

                        {columnVisible.map((column, ind) => {
                            return (

                                <div key={column.header} style={{ gap: "8px", display: "flex", alignItems: "center" }}>
                                    <Checkbox inputId={column.header} value={column} onChange={onColumnChange} disabled={column.header == "Member #" || column.header == "MemberName"} checked={column.visible} />
                                    <label htmlFor={column.header} className="ml-2">
                                        {column.header}
                                    </label>
                                </div>

                            );
                        })}

                    </Box>
                    <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#F1F5F9", paddingTop: "24px", paddingBottom: "24px", justifyContent: "space-between", marginLeft: "-30px", marginRight: "-30px", }}>

                        <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#288d2c" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "138px", height: "48px", marginLeft: "30px" }} startIcon={<CheckCircleOutlineIcon />} onClick={applyFilter} >Apply</Button>
                        <Button variant="outlined" sx={{ borderColor: "#64748B", color: "#64748B", "&:hover": { borderColor: "#64748B" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", marginRight: "30px" }} startIcon={<ClearIcon sx={{ color: "#64748B", fontSize: "24px" }} />} onClick={() => setArrangeTable(false)}>Clear</Button>


                    </Box>
                </Box>


            </Dialog >

        </Box>
    )
}


export default ReceiptIssued