import { Box, Typography, Button } from "@mui/material"
import SettingsIcon from '@mui/icons-material/Settings';
import Modal from '@mui/material/Modal';
import { useState, useRef, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
// import { Divider } from 'primereact/divider';
import "../styles/cuttoff.css"
import { Slider } from "primereact/slider";
import { InputText } from "primereact/inputtext";
import Divider from '@mui/material/Divider';
// import { Button } from 'primereact/button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DownloadIcon from '@mui/icons-material/Download';
import TableChartIcon from '@mui/icons-material/TableChart';

import { Dropdown } from 'primereact/dropdown';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

import axios from 'axios';
import { useSelector, useDispatch, } from "react-redux";
import { Form } from "./form";
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { InputText } from 'primereact/inputtext';
// import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Checkbox } from "primereact/checkbox";
import { CSVLink } from 'react-csv'
import { updateModalOpen, } from "../redux/executiveReducer";
import { InputNumber } from "primereact/inputnumber";

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import * as XLSX from 'xlsx';
export const CutOff = () => {

    const [filteredData, setFilterData] = useState()
    const [visible, setVisible] = useState(false);
    const cutoffData = useSelector(state => state.executive.cutoffData)
    const formPayload = useSelector(state => state.executive.formPayload)
    const [customers, setCustomers] = useState(null);
    const [filterState, setFilterState] = useState(false)

    const [loading, setLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [totalRecords, setTotalRecords] = useState(null)
    const [arrangeTable, setArrangeTable] = useState(false)
    const [selectedColumn, setSelectedColumn] = useState([])
    const [exportCSV, setExportCSV] = useState([])
    const modalOpen = useSelector(state => state.executive.modalOpen)
    const [download, setDownload] = useState([])
    const dispatch = useDispatch()
    const [seperatedColumn, setSeperatedColumn] = useState([])
    const isSidebarOpen = useSelector((state) => state.executive.open);

    //    console.log(formPayload)
    const CustomFilter = (options) => {

        return (
            <InputText style={{ width: "182px", height: "36px" }} placeholder="search" value={options.value} onChange={(e) => options.filterApplyCallback(e.target.value,)} />
        );
    };


    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => { options.filterApplyCallback(e.target.value); }} dateFormat="dd-mm-yyyy" placeholder="dd-mm-yyyy" />;
    };
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    const dateOfJoinBody = (rowData) => {
        const originalDate = new Date(rowData.doj);
        const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = originalDate.getDate().toString().padStart(2, '0');
        const year = originalDate.getFullYear();

        const formattedDate = `${day}-${month}-${year}`

        return formattedDate;
    }
    const decimalshare = (rowData) => {
        console.log(rowData.share)
        if (isNaN(rowData.share) || rowData.share == null)
            return "0.00";

        return parseFloat(rowData.share).toFixed(2)
    }
    const decimalsub = (rowData) => {

        if (isNaN(rowData.subscription) || rowData.subscription == null)
            return "0.00";

        return parseFloat(rowData.subscription).toFixed(2)
    }
    const decimalLoan = (rowData) => {

        if ((isNaN(rowData.loanbalance) || rowData.loanbalance == null )&& (isNaN(rowData.loanrepayment) || rowData.loanrepayment == null ))
            return "0.00";

                return parseFloat(parseInt(rowData.loanbalance)+parseInt(rowData.loanrepayment)).toFixed(2)
    }
    const decimalBonus = (rowData) => {
       
            return parseFloat(rowData.bonus).toFixed(2)
        
        
    }
    const decimalSd = (rowData) => {

        if (isNaN(rowData.sd) || rowData.sd == null)
            return "0.00";

        return parseFloat(rowData.sd).toFixed(2)
    }
    const decimalBr = (rowData) => {

        if (isNaN(rowData.brbalance) || rowData.brbalance == null)
            return "0.00";

        return parseFloat(rowData.brbalance).toFixed(2)
    }
    const AddressBody = (rowData) => {
        return rowData.add1 + ", " + rowData.add2 + ", " + rowData.add3 + ", " + rowData.add4

    }
    const DeceasedDate=(rowdata)=>{
        if(rowdata.deceased){
            // console.log("inside the table")
            const originalDate = new Date(rowdata.deathdate);
                const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                const day = originalDate.getDate().toString().padStart(2, '0');
                const year = originalDate.getFullYear();
        return  `${day}-${month}-${year}`

        }
        else{
            return ""
        }
        
    }

    const [columnVisible, setColumnVisible] = useState([
        { field: "memberid", header: "MNo", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: true, filterElement: CustomFilter, filterField: "memberid", datatype: "numeric" },
        { field: "membername", sortable: true, header: "Name", filterField: "membername", filter: true, filterPlaceholder: "Search", visible: true, frozen: true, filterElement: CustomFilter },
        { field: "newicno", sortable: true, filter: true, header: "NRIC(New)", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },
        { field: "oldicno", sortable: true, filter: true, header: "NRIC(Old)", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },
        { field: "phoneno", header: "Phone1", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, },
        { field: "phone2", header: "Phone2", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, },
        { field: "mobileno", sortable: true, filter: true, header: "HP", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },
        { field: "emailid", sortable: true, filter: true, header: "Email Add", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },

        { field: "doj", sortable: true, filter: true, header: "Dt-Join", filterPlaceholder: "Search", visible: true, filterElement: dateFilterTemplate, body: dateOfJoinBody, filterField: "doj" },
        { field: "age", sortable: true, filter: true, header: "Age", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },
        { field: "sex", sortable: true, filter: true, header: "Sex", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },
        { field: "race", sortable: true, filter: true, header: "Race", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },
        { field: "photo", sortable: true, filter: true, header: "Photo", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },

        { field: "share", header: "Share", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, body: decimalshare },
        { field: "subscription", sortable: true, filter: true, header: "Subscription", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, body: decimalsub },
        { field:"bonus", sortable: true, filter: true, header: "Bonus", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter,  body: decimalBonus },
        { field: "loanbalance", header: "Loan", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, body: decimalLoan },
        { field: "sd", header: "SD", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, body: decimalSd },
        { field: "brbalance", header: "Borrowings", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, body: decimalBr },
        { field: "estate", sortable: true, filter: true, header: "Estate", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },
        { field: "state", sortable: true, filter: true, header: "State", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },
        { field: "postalcode", sortable: true, filter: true, header: "Postal Code", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },
        { field: "add1", sortable: true, filter: true, header: "Add1", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },
        { field: "add2", sortable: true, filter: true, header: "Add2", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },
        { field: "add3", sortable: true, filter: true, header: "Add3", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },
        { field: "add4", sortable: true, filter: true, header: "Add4", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },
        { field: "region", sortable: true, filter: true, header: "Region", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },
        { field: "memberstatus", sortable: true, filter: true, header: "Mykad Copy", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, body: (row) => { if (row.memberstatus) { return "Y" } else { return "N" } } },
        { field: "mykadnominee", sortable: true, filter: true, header: "Mykad Nominee", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, body: (row) => { if (row.mykadnominee) { return "Y" } else { return "N" } } },
        { field: "nominationletter", sortable: true, filter: true, header: "Nomination Letter", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, body: (row) => { if (row.nominationletter) { return "Y" } else { return "N" } } },
        { field: "nomail", header: "No Mailer", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, body: (row) => { if (row.nomail) { return "Y" } else { return "N" } } },
        { field: "deceased", header: "Deceased", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, body: (row) => { if (row.deceased) { return "Y" } else { return "N" } } },
        { field: "deathdate",header: "Deceased Date", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter,body:DeceasedDate},
        { field: "deathcertfile", sortable: true, filter: true, header: "Death Cert file", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, body: (row) => { if (row.deathcertfile) { return "Y" } else { return "N" } } },
        { field: "m_bank", sortable: true, filter: true, header: "M_Bank", filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },

        // { field: "", sortable: true, filter: true, header: "Nominee Copy", filterPlaceholder: "Search", visible: true ,frozen:false,filterElement:CustomFilter},





        // { field: "", header: "Deceased Date", sortable: true, filter: true, filterPlaceholder: "Search", visible: true ,frozen:false,filterElement:CustomFilter,body:(row)=>{if(row.disciplinaryaction){return "Y" }else{return "N"}}},

        { field: "disciplinaryaction", header: "Displ.Action", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, body: (row) => { if (row.disciplinaryaction) { return "Y" } else { return "N" } } },
        { field: "director", header: "Director", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, body: (row) => { if (row.director) { return "Y" } else { return "N" } } },
        { field: "observer", header: "Observer", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, body: (row) => { if (row.observer) { return "Y" } else { return "N" } } },
        { field: "delegates", header: "Delegate", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, body: (row) => { if (row.delegates) { return "Y" } else { return "N" } } },
        { field: "company", header: "Company", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, body: (row) => { if (row.company) { return "Y" } else { return "N" } } },
        { field: "junior", header: "Junior", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter, body: (row) => { if (row.junior) { return "Y" } else { return "N" } } },
        { field: "terminated", header: "Terminated", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, frozen: false, filterElement: CustomFilter },
    ])
    useEffect(() => {
        if (cutoffData.length > 0) {
            let exportHeaders = columnVisible.filter((item) => item.visible).map(item =>   item.field )
            let headers=columnVisible.filter((item) => item.visible).map(item =>   item.header)
            // console.log(exportHeaders)
            const details= cutoffData.map(item=>{
                let arrange={}
                arrange.memberid=item.memberid
                arrange.membername=item.membername !== null?item.membername : ""
                arrange.newicno=item.newicno !== null?item.newicno : ""
                arrange.oldicno=item.oldicno !== null?item.oldicno : ""
                arrange.phoneno=item.phoneno !== null?item.phoneno : ""
                arrange.phone2=item.phone2 !== null?item.phone2 : ""
                arrange.mobileno=item.mobileno !== null?item.mobileno : ""
                arrange.emailid=item.emailid !== null?item.emailid : ""
                arrange.doj=item.doj !== null?item.doj : ""
                arrange.age=item.age !== null?item.age : ""
                arrange.sex=item.sex !== null?item.sex : ""
                arrange.race=item.race !== null?item.race : ""
                arrange.photo=item.photo !== null?item.photo : ""
                arrange.share=item.share !== null?item.share : 0
                arrange.subscription=item.subscription !== null?item.subscription : 0
                arrange.bonus=item.bonus !== null?item.bonus : 0
                arrange.loanbalance=parseInt(item.loanbalance)+parseInt(item.loanrepayment)
                arrange.sd=item.sd !== null?item.sd : 0
                arrange.brbalance=item.brbalance !== null?item.brbalance : 0
                arrange.estate=item.estate !== null?item.estate : ""
                arrange.state=item.state !== null?item.state : ""
                arrange.postalcode=item.postalcode !== null?item.postalcode : ""
                arrange.add1=item.add1 !== null?item.add1 : ""
                arrange.add2=item.add2 !== null?item.add2 : ""
                arrange.add3=item.add3 !== null?item.add3 : ""
                arrange.add4=item.add4 !== null?item.add4 : ""
                arrange.region=item.region !== null?item.region : ""
                // arrange.memberstatus=item.add4
                // arrange.add4=item.memberstatus
                arrange.memberstatus=item.memberstatus!== null?item.memberstatus : ""
                arrange.mykadnominee=item.mykadnominee!== null?item.mykadnominee : ""
                arrange.nominationletter=item.nominationletter !== null?item.nominationletter : ""
                arrange.nomail=item.nomail !== null?item.nomail : ""
                arrange.deceased=item.deceased !== null?item.deceased : ""
                arrange.deathdate=item.deathdate !== null?item.deathdate : ""
                arrange.deathcertfile=item.deathcertfile !== null?item.deathcertfile : ""
                arrange.m_bank=item.m_bank !== null?item.m_bank : ""
                arrange.disciplinaryaction=item.disciplinaryaction !== null?item.disciplinaryaction : ""
                arrange.director=item.director !== null?item.director : ""
                arrange.observer=item.observer !== null?item.observer : ""
                arrange.delegates=item.delegates !== null?item.delegates : ""
                arrange.company=item.company !== null?item.company : ""
                arrange.junior=item.junior !== null?item.junior : ""
                arrange.terminated=item.terminated !== null?item.terminated : ""


                arrange = Object.keys(arrange).filter(objKey =>{
                // console.log(objKey)
               return exportHeaders.includes(objKey)
             }

                 ).reduce((newObj, key) =>
                {
                    // console.log(key)
                    newObj[key] = arrange[key];
                    // console.log(newObj)
                    return newObj;
                }, {}
            );
            return arrange
        })
        
            // console.log(details)
            const arraydownload = details.map(item => {

                // console.log(item)

                const originalDate = new Date(item.doj);
                //console.log(item.doj)
                const month = String ((originalDate.getMonth() + 1)).padStart(2, '0'); // Months are zero-based
                const day =  String (originalDate.getDate()).padStart(2, '0');
                const year = originalDate.getFullYear();

                const formattedDate = `${day}-${month}-${year}`
                // item={...item,}
                item = { ...item, doj: formattedDate }
                if (item.deathdate){
                    const deceased = new Date(item.deathdate);
                    const deceasedmonth = String ((deceased.getMonth() + 1)).padStart(2, '0'); // Months are zero-based
                    const deceasedday = String (deceased.getDate()).padStart(2, '0');
                    const deceasedyear = deceased.getFullYear();
    
                    const deceasedDate = `${deceasedday}-${deceasedmonth}-${deceasedyear}`
                    item={...item, deathdate:deceasedDate}
                }
                else{
                    item={...item, deathdate:""}
                }
                


                item = { ...item, share: parseFloat(item.share).toFixed(2) }
                item = { ...item, subscription: parseFloat(item.subscription).toFixed(2) }
                item = { ...item, loanbalance: parseFloat(item.loanbalance).toFixed(2) }
                item = { ...item, sd: parseFloat(item.sd).toFixed(2) }
                item = { ...item, brbalance: parseFloat(item.brbalance).toFixed(2) }
                if (item.disciplinaryaction) {
                    // console.log("?????????????????????")
                    item = { ...item, disciplinaryaction: "Y" }
                }

                else {
                    // console.log("?????????????????????")
                    item = { ...item, disciplinaryaction: "N" }
                }
                // return item
                if (item.director) {
                    item = { ...item, director: "Y" }
                }
                else {
                    item = { ...item, director: "N" }
                }
                if (item.observer) {
                    item = { ...item, observer: "Y" }
                }
                else {
                    item = { ...item, observer: "N" }
                }
                if (item.delegates) {
                    item = { ...item, delegates: "Y" }
                }
                else {
                    item = { ...item, delegates: "N" }
                }
                if (item.company) {
                    item = { ...item, company: "Y" }
                }
                else {
                    item = { ...item, company: "N" }
                }
                if (item.junior) {
                    item = { ...item, junior: "Y" }
                }
                else {
                    item = { ...item, junior: "N" }
                }
                if (item.nomail) {
                    item = { ...item, nomail: "Y" }
                }
                else {
                    item = { ...item, nomail: "N" }
                }
                if (item.deceased) {
                    item = { ...item, deceased: "Y" }
                }
                else {
                    item = { ...item, deceased: "N" }
                }
                if (item.mykadnominee) {
                    item = { ...item, mykadnominee: "Y" }
                }
                else {
                    item = { ...item, mykadnominee: "N" }
                }
                if (item.memberstatus) {
                    item = { ...item, memberstatus: "Y" }
                }
                else {
                    item = { ...item, memberstatus: "N" }
                }
                if (item.deathcertfile) {
                    item = { ...item, deathcertfile: "Y" }
                }
                else {
                    item = { ...item, deathcertfile: "N" }
                }
                if (item.nominationletter) {
                    item = { ...item, nominationletter: "Y" }
                }
                else {
                    item = { ...item, nominationletter: "N" }
                }
                return item
            })
            // const dateRange=formPayload['CO']?.dr.length>1? `${formatDate(new Date (formPayload['CO']?.dr[0]))}-${formatDate( new Date(formPayload['CO']?.dr[1]))}`:formatDate( new Date(formPayload['CO']?.dr[0]))
            // const dateReport=formPayload['CO']?.dr.length>1? "Report Date":"cut off Date"
            // const introText="KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)"
            // const second_text="cut off balance report"
            // const mid=formPayload['CO']?.mid.length>0 ?`${formPayload['CO']?.mid[0]}-${formPayload['CO']?.mid[1]}`:`0-999999`
            // const config=` ${dateReport}:${dateRange}\nMno Range:${mid}\nprint Date:${formatDate(new Date())}`
            // // console.log(config)
            // const title=introText+"\n"+second_text+"\n"+config
            // let combined_array = [

            //     [title], // String
                
            //     // [second_text],
            //     // [config],
               
               
            //     // Empty row for spacing
            //     // Object.keys(arraydownload[0]), // Headers
            //     headers,
            //     ...arraydownload // Object values
            //   ];
            setDownload(arraydownload)
        }
    }, [cutoffData])
    const formatDate = (date) => {
        const day = date.getDate();
        const monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const month = date.getMonth()+1
        const year = date.getFullYear();
      
        return `${day}/${month}/${year}`;
      };
    const globalFilterFields = columnVisible.map((obj) => { return obj.field })
    useEffect(() => {
        if (cutoffData.length > 0) {
            let exportHeaders = [...exportCSV]
            exportHeaders = columnVisible.filter((item) => item.visible).map(item => { return { label: item.header, key: item.field } })

            setExportCSV(exportHeaders)


        }


    }, [])
    // const onColumnChange = ({ target }) => {
    //     console.log(target)
    //     let columns = [...selectedColumn]
    //     columns = columns.map((val, ind) => {
    //         if (target.value == ind) {
    //             val.visible = false
    //             console.log(val)
    //             return val
    //         }
    //         return val
    //     })
    //     console.log(columns)
    //     setSelectedColumn(columns)
    // }

    // const onColumnChange = (e) => {
    //     let _selectedCategories = [...selectedColumn];

    //     if (e.checked)
    //         _selectedCategories.push(e.value);
    //     else
    //         _selectedCategories = _selectedCategories.filter(category => category.header !== e.value.header);

    //     setSelectedColumn(_selectedCategories);
    // }
    const onColumnChange = (e) => {
        let column = [...columnVisible]
        let _selectedCategories = [...seperatedColumn];
        if (e.checked) {
            //console.log(e.value)
            let deselect = column.filter(item => item.header == e.value.header).map(item => { item.visible = true; return item })
            //console.log(column)
            deselect = [...column, ...deselect];
            deselect = [...new Set(deselect)]
            //console.log(deselect)
            setSelectedColumn(deselect)
            _selectedCategories.push(e.value);
        }
        else {
            //console.log(e.value)
            let deselect = column.filter(item => item.header == e.value.header).map(item => { item.visible = false; return item })
            //console.log(column)
            deselect = [...column, ...deselect];
            deselect = [...new Set(deselect)]
            //console.log(deselect)
            setSelectedColumn(deselect)
            _selectedCategories = _selectedCategories.filter(category => category.header !== e.value.header);


        }
        setSeperatedColumn(_selectedCategories)

    };
    const applyFilter = () => {
        let columns = [...columnVisible]


        // columns=columns.map(column=>{
        //     selectedColumn.some(item=>)
        //     // console.log(array)
        //     return column

        // })
        //console.log(columns)
        //console.log(selectedColumn)

        setColumnVisible(selectedColumn)
        setArrangeTable(false)
        let exportHeaders = [...exportCSV]
        //console.log(exportHeaders)
        exportHeaders = selectedColumn.filter((item) => item.visible).map(item => { return { label: item.header, key: item.field } })
        //console.log(exportHeaders)

        setExportCSV(exportHeaders)
    }


    useEffect(() => {
        setFilterData(cutoffData)
       // console.log(cutoffData[0])
        loadLazyData()
    }, [cutoffData])
    let networkTimeout = null
    const loadLazyData = () => {
        setLoading(true);

        if (networkTimeout) {
            clearTimeout(networkTimeout);
        }

        //imitate delay of a backend call
        networkTimeout = setTimeout(() => {

            setTotalRecords(cutoffData.length);
            setCustomers(cutoffData);
            setLoading(false);

        }, Math.random() * 1000 + 250);
    };
    const [filters, setFilters] = useState(() => {
        let filter_obj = {}
        filter_obj['global'] = { value: null, matchMode: FilterMatchMode.CONTAINS }
        columnVisible.map((obj) => {
            filter_obj[obj.field] = { value: null, matchMode: FilterMatchMode.CONTAINS }
        })
        return filter_obj
    }
        //     {
        //     global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //     membername: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //     sex: { value: null, matchMode: FilterMatchMode.IN },
        //     estate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //     state: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //     share: { value: null, matchMode: FilterMatchMode.EQUALS },
        //     subscription: { value: null, matchMode: FilterMatchMode.EQUALS },
        //     memberid: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //     photo: { value: null, matchMode: FilterMatchMode.IN },
        //     race: { value: null, matchMode: FilterMatchMode.IN },
        //     doj: {   value: null, matchMode: FilterMatchMode.CONTAINS },
        //     age:{value:null, matchMode: FilterMatchMode.CONTAINS}


        // }
    );

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {

            setSelectAll(true);
            setSelectedCustomers(cutoffData);

        } else {
            setSelectAll(false);
            setSelectedCustomers([]);
        }
    };
    const onSelectionChange = (event) => {
        const value = event.value;
        //console.log(value)
        setSelectedCustomers(value);
        //console.log(selectedCustomers)
        setSelectAll(value.length === totalRecords);
    };
    useEffect(() => {
        //console.log(selectedCustomers)
    }, [selectedCustomers])

    const clearFilter = () => {

        let filter_obj = {}
        filter_obj['global'] = { value: null, matchMode: FilterMatchMode.CONTAINS }
        columnVisible.map((obj) => {
            filter_obj[obj.field] = { value: null, matchMode: FilterMatchMode.CONTAINS }
        })
        setFilters(filter_obj)

        // setFilters(
        //     {
        //         global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         membername: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         sex: { value: null, matchMode: FilterMatchMode.IN },
        //         estate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         state: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         share: { value: null, matchMode: FilterMatchMode.EQUALS },
        //         subscription: { value: null, matchMode: FilterMatchMode.EQUALS },
        //         memberid: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         photo: { value: null, matchMode: FilterMatchMode.IN },
        //         race: { value: null, matchMode: FilterMatchMode.IN },
        //         doj: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         age:{value:null, matchMode: FilterMatchMode.CONTAINS}

        //     }
        // )
        setGlobalFilterValue('')
        setFilterState(false)
    }

    const addCurrencyAndCommafy = (num) => {

        let str = parseFloat(num).toFixed(2).toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        return "RM " + str.join('.');

    }

    const shTotal = () => {
        let total = 0;

        for (let row of cutoffData) {

            if (isNaN(row.share) || row.share == null)
                total += 0;
            else
                total += parseFloat(row.share);
        }

        return total;
    };

    const sbTotal = () => {
        let total = 0;

        for (let row of cutoffData) {

            if (isNaN(row.subscription) || row.subscription == null)
                total += 0;
            else
                total += parseFloat(row.subscription);
        }

        return total;
    };
    const sdTotal = () => {
        let total = 0;

        for (let row of cutoffData) {

            if (isNaN(row.sd) || row.sd == null)
                total += 0;
            else
                total += parseFloat(row.sd);
        }

        return total;

    }
    const borrowTotal = () => {
        let total = 0;

        for (let row of cutoffData) {

            if (isNaN(row.brbalance) || row.brbalance == null)
                total += 0;
            else
                total += parseFloat(row.brbalance);
        }

        return total;

    }
    const loanTotal = () => {
        let total = 0;

        for (let row of cutoffData) {

            if ( (isNaN(row.loanbalance) || row.loanbalance == null)&&  (isNaN(row.loanrepayment) ||row.loanrepayment == null))
                total += 0;
            else
                total += parseFloat(parseInt(row.loanbalance)+parseInt(row.loanrepayment));
        }

        return total;

    }
    const bonusTotal=()=>{
        let total = 0;
        for (let row of cutoffData) {

            if (isNaN(row.bonus) || row.bonus == null)
                total += 0;
            else
                total += parseFloat(row.bonus);
        }

        return total;

    }

    const grandTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Grand Total :</span>{" "}
                {addCurrencyAndCommafy(shTotal() + sbTotal() + loanTotal() + sdTotal() + borrowTotal())}
            </span>
        );
    };

    const shaTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Share Total : </span>{addCurrencyAndCommafy(shTotal())}
            </span>
        )
    }

    const subTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Subs Total : </span>{addCurrencyAndCommafy(sbTotal())}
            </span>
        )
    }

    const lTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Loan Total : </span>{addCurrencyAndCommafy(loanTotal())}
            </span>
        )
    }

    const SDTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>SD Total : </span>{addCurrencyAndCommafy(sdTotal())}
            </span>
        )
    }

    const BorrowTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Borrow Total : </span>{addCurrencyAndCommafy(borrowTotal())}
            </span>
        )
    }
    const BonusTotal=()=>{
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Bonus Total : </span>{addCurrencyAndCommafy(bonusTotal())}
            </span>
        )
    }

    const footGroup = (
        <ColumnGroup>
            <Row>
                <Column footer={grandTotal} colSpan={15} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />

                <Column footer={shaTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={subTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={BonusTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={lTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={SDTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={BorrowTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />


            </Row>
        </ColumnGroup>

    )

    // const exportHeaders = [{ key: "memberid", label: "Member #",  },
    // { key: "membername", label: "MemberName",  },
    // { key: "newicno", label: "NRIC New",},
    // { key: "oldicno", label: "NRIC Old",},
    // // { key:"doj",  label:"Date Joined"filterPlaceholder:"Search"filterElement:{dateFilterTemplate} },
    // { key: "", label: "Age",},
    // { key: "sex", label: "Sex",},
    // { key: "race", label: "Race",},
    // { key: "state", label: "State",},
    // { key: "estate", label: "Estate Name",},
    // { key: "region", label: "Region",},
    // { key: "postalcode", label: "Post Code",},
    // { key: "photo", label: "Photo",},
    // { key: "mykadnominee", label: "Nominee MyKad",},
    // { key: "share", label: "Share", },
    // { key: "subscription", label: "Subscription",},
    // { key: "loanbalance", label: "Loan",},
    // { key: "sdbalance", label: "SD",},
    // { key: "brbalance", label: "Borrowings",},
    // { key: "bonus", label: "Bonus",},
    // { key: "nomail", label: "No Mailer",},
    // { key: "deceased", label: "Deceased", },
    // { key: "disciplinaryaction", label: "Displ.Action",},
    // { key: "director", label: "Director",},
    // { key: "observer", label: "Observer",},
    // { key: "delegates", label: "Delegate",},
    // { key: "company", label: "Company",},
    // { key: "junior", label: "Junior", },
    // { key: "terminated", label: "Terminated",},
    // ]
    const formattingdate = (date) => {
        if (date) {
            const originalDate = new Date(date);
            const year = originalDate.getUTCFullYear();
            const month = String(originalDate.getUTCMonth() + 1).padStart(2, '0');
            const day = String(originalDate.getUTCDate()).padStart(2, '0');

            let formattedDate = `${day}-${month}-${year}`;
            return formattedDate;
        }
        else {
            return date
        }

    }

    const exportExcel = () => {
        let headers = columnVisible.map(col => ({ header: col.header, key: col.field }));
        headers = [{ header: "SN", key: "SN" }, ...headers];
    
        const data = download.map((item, index) => {
            return { SN: index + 1, ...item };
        });
    
        const headersBold = headers.map(({ header }) => ({
            v: header,
            t: 's',
            s: {
                font: { bold: true, size: 10 }
            }
        }));
    
        const additionalRows = [
            ['','KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)'],
            [],
            ['','Cut Off Balance Report'],
            ['',`Report Date : ${formPayload['CO'].dr.length === 1? formattingdate(formPayload['CO'].dr[0]) : formattingdate(formPayload['CO'].dr[0])+" TO "+formattingdate(formPayload['CO'].dr[1])}`],
            ['',`Mno Range : ${formPayload['CO'].mid.length === 0?" - Not Selected - ": formPayload['CO'].mid[0]+" TO "+formPayload['CO'].mid[1]}`],
            ['', `Share Bal. (RM) :- ${formPayload['CO'].sh.length === 0?"": formPayload['CO'].sh[0]+" TO "+formPayload['CO'].sh[1]}`],
            ['',`Print Date : ${formattingdate(new Date())}`],
            []
        ];
    
        // Convert array of objects to array of arrays (2D array) for the data section
        const dataRows = data.map(obj => headers.map(h => {
            let value;
            const amountRelatedKeys = ["share","subscription","bonus","loanbalance","sd","brbalance"]
            if(amountRelatedKeys.includes(h.key)){
                value = parseInt(parseFloat(obj[h.key]).toFixed(2).split('.')[1]) === 0?parseInt(obj[h.key]):obj[h.key]
            }
            else
                value = obj[h.key]
            return ({
            v: value,
            t: typeof value === 'number' ? 'n' : 's',
            s: {
                alignment: { horizontal: typeof value === 'number' ? 'right' : 'left' },
                font: { size: 8, name: 'Times New Roman' }
            }
        })
    }));
    
        const dataArray = [...additionalRows, headersBold, ...dataRows];
    
        // Convert data to worksheet
        const ws = XLSX.utils.aoa_to_sheet(dataArray);
    
        // Create workbook and add the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
        // Generate XLSX file and trigger download
        XLSX.writeFile(wb, 'Cut Off Balance Report.xlsx');
    };

    return (
        <Box className="fulldiv" style={{ width: isSidebarOpen?"calc(100vw - 319px)":"calc(100vw - 95px)", height: "calc(100vh - 64px)", }} >



            <Dialog visible={modalOpen} style={{ width: '790px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={false} closable={false} resizable={false} >


                <Form formAlone={true} api="CO" />



            </Dialog>
            <Box sx={{ marginLeft: "24px", padding: "8px 0px 8px 0px", marginRight: "16px", }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", gap: "12px", }}>
                        <SummarizeOutlinedIcon sx={{ fontSize: "32px", color: "#288d2c" }} />
                        <Box>
                            <Typography sx={{ fontSize: "20px", fontWeight: "600", lineHeight: "28px", color: "#101010" }}>Cut-off Date Report</Typography>
                            <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#64748B" }}>Found <span style={{ fontWeight: "700" }}>{cutoffData.length}</span> records on <span style={{ fontWeight: "700" }}>{formattingdate(formPayload['CO']?.dr[0])}</span> to <span style={{ fontWeight: "700" }}>{formattingdate(formPayload['CO']?.dr[1])}</span> with applied Configure</Typography>
                        </Box>

                    </Box>
                    <Box sx={{ alignSelf: "end" }}>
                        <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#288d2c" }, gap: "8px", fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "201px", height: "48px" }} startIcon={<SettingsIcon sx={{ fontSize: "24px", }} />} onClick={() => dispatch(updateModalOpen(true))}>Data Re-Configure</Button>
                    </Box>

                </Box>
                <Box sx={{ marginTop: "24px", }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between",width:"100%",flexWrap:"wrap"}}>


                        <Box sx={{ display: "flex", alignItems: "center" }}>

                            <Box>

                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText style={{ width: "655px", height: "36px" }} placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} />
                                </span>
                            </Box>
                            <Box sx={{ color: "#64748B", width: "178px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "16px", alignSelf: "end", cursor: "pointer", textTransform: "none", gap: "8px" }} onClick={clearFilter}>
                                <ClearAllIcon sx={{ fontSize: "24px", color: "#CBD5E1" }} />
                                <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#CBD5E1" }}></Typography>
                                Clear Filter
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "16px", alignSelf: 'end' }}>
                            <Box sx={{ padding: "12px" }}> {filterState ? <FilterAltOffIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(false)} /> : <FilterAltIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(true)} />}</Box>

                            <Button type="button" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" ><DownloadIcon sx={{ color: "#64748B", fontSize: "24px" }} /></Button>
                            <Box sx={{ padding: "12px" }}><TableChartIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => { setArrangeTable(true); setSeperatedColumn(columnVisible) }} /> </Box>

                        </Box>

                    </Box>
                    <Box sx={{ marginTop: "24px", }}>
                        <div className="card">
                            <DataTable value={cutoffData} filters={filters} paginator footerColumnGroup={footGroup} rows={10} dataKey="memberid" style={{ backgroundColor: "#F1F5F9" }} scrollable scrollHeight="650px" filterDisplay={filterState ? "row" : ""} loading={loading} columnResizeMode="expand" removableSort resizableColumns
                                selection={selectedCustomers} selectionMode="checkbox" onSelectionChange={onSelectionChange} selectAll={selectAll} filterIcon={false} onSelectAllChange={onSelectAllChange} emptyMessage="No customers found." globalFilterFields={globalFilterFields}>
                                <Column styleClass="custom-column-style" selectionMode="multiple" headerStyle={{ width: "100px", background: "#F1F5F9" }} frozen />
                                <Column header="SN" headerStyle={{ width: '500px' }} body={(data, options) => options.rowIndex + 1} frozen></Column>

                                {columnVisible.filter(col => col.visible).map(col => <Column className="custom-column-style" style={{ width: "500px" }} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}


                                {/* <Column field="memberid" header="Member #" sortable filter filterPlaceholder="Search" visible />
                                <Column field="membername" sortable header="MemberName" filterField="membername" filter filterPlaceholder="Search" />
                                <Column field="newicno" sortable filter header="NRIC New" filterPlaceholder="Search" />
                                <Column field="oldicno" sortable header="NRIC Old" filter filterPlaceholder="Search" />
                                <Column field="doj" sortable header="Date Joined" filter filterPlaceholder="Search" filterElement={dateFilterTemplate} />
                                <Column field="" sortable header="Age" filter filterPlaceholder="Search" />
                                <Column field="sex" sortable header="Sex" filter filterPlaceholder="Search" />
                                <Column field="race" sortable header="Race" filter filterPlaceholder="Search" />
                                <Column field="state" sortable header="State" filter filterPlaceholder="Search" />
                                <Column field="estate" sortable header="Estate Name" filter filterPlaceholder="Search" />
                                <Column field="region" sortable header="Region" filter filterPlaceholder="Search" />
                                <Column field="postalcode" sortable header="Post Code" filter filterPlaceholder="Search" />
                                <Column field="photo" sortable header="Photo" filter filterPlaceholder="Search" />
                                <Column field="mykadnominee" sortable header="Nominee MyKad" filter filterPlaceholder="Search" />
                                <Column field="share" header="Share" sortable filter filterPlaceholder="Search" />
                                <Column field="subscription" sortable header="Subscription" filter filterPlaceholder="Search" />
                                <Column field="loanbalance" header="Loan" filter filterPlaceholder="Search" />
                                <Column field="sdbalance" header="SD" filter filterPlaceholder="Search" />
                                <Column field="brbalance" header="Borrowings" filter filterPlaceholder="Search" />
                                <Column field="representative.name" header="Bonus" filter filterPlaceholder="Search" />
                                <Column field="nomail" header="No Mailer" filter filterPlaceholder="Search" />
                                <Column field="deceased" header="Deceased" filter filterPlaceholder="Search" />
                                <Column field="disciplinaryaction" header="Displ.Action" filter filterPlaceholder="Search" />
                                <Column field="director" header="Director" filter filterPlaceholder="Search" />
                                <Column field="observer" header="Observer" filter filterPlaceholder="Search" />
                                <Column field="delegates" header="Delegate" filter filterPlaceholder="Search" />
                                <Column field="company" header="Company" filter filterPlaceholder="Search" />
                                <Column field="junior" header="Junior" filter filterPlaceholder="Search" />
                                <Column field="terminated" header="Terminated" filter filterPlaceholder="Search" />  */}


                            </DataTable>
                        </div>
                    </Box>
                </Box>


            </Box>
            <Dialog visible={arrangeTable} style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={true} closable={false} resizable={false} >


                <Box style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", padding: "30px 30px 0px 30px", borderRadius: "0px", display: "flex", flexDirection: "column", gap: "24px", }}>
                    <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#f2f6fc" }}>
                        <TableChartIcon sx={{ fontSize: "24px", color: "#288d2c" }} />
                        <Typography sx={{ fontSize: "18px", fontWeight: 600, lineHeight: "28px" }}>Table Column Configure</Typography>
                    </Box>
                    <Box>


                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText style={{ width: "461px", height: "36px" }} placeholder="Search" onChange={onGlobalFilterChange} />
                        </span>

                    </Box>
                    <Box sx={{ height: "461px", overflowY: "scroll", display: "flex", flexDirection: "column", gap: "20px" }}>

                        {columnVisible.map((column, ind) => {
                            return (

                                <div key={column.header} style={{ gap: "8px", display: "flex", alignItems: "center" }}>
                                    <Checkbox inputId={column.header} value={column} onChange={onColumnChange} disabled={column.header == "Member #" || column.header == "MemberName"} checked={column.visible} />
                                    <label htmlFor={column.header} className="ml-2">
                                        {column.header}
                                    </label>
                                </div>

                            );
                        })}

                    </Box>
                    <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#F1F5F9", paddingTop: "24px", paddingBottom: "24px", justifyContent: "space-between", marginLeft: "-30px", marginRight: "-30px", }}>

                        <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#288d2c" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "138px", height: "48px", marginLeft: "30px" }} startIcon={<CheckCircleOutlineIcon />} onClick={applyFilter} >Apply</Button>
                        <Button variant="outlined" sx={{ borderColor: "#64748B", color: "#64748B", "&:hover": { borderColor: "#64748B" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", marginRight: "30px" }} startIcon={<ClearIcon sx={{ color: "#64748B", fontSize: "24px" }} />} onClick={() => setArrangeTable(false)}>Clear</Button>


                    </Box>
                </Box>


            </Dialog >

        </Box >

    )
}